import React from "react";
import { makeAutoObservable, observable, toJS, computed, reaction } from "mobx";
import { generate as generateRandomString } from "randomstring";
import { cloneDeep, debounce, findIndex, isEqual } from "lodash";
import { Modal} from "antd";

//IndexedDB
import { db } from "../db/bookDb";

//helpers
import { removeKey, doSearchReplace, doKeywordCount } from "../utils/helper";
import { initBody, initTemplate, getPresentableChapterType, initBook } from "../utils/initials";
import { getChaptersFromJSONCache, getWordsCount } from "../components/Plate/helpers";

// types
import { ChapterMeta } from "../types/chapter";
import { IChapterTemplateBase } from "../types/chapter";

// API
import { AtticusClient, ExportResponse } from "../api/atticus.api";

import { GetBookFromDB, GetChapterMetaFromDB, UpdateBookInDB, UpdateChapterMeta,
  GetChapterTemplates, UpdateChapterTemplateInDB,
  SaveErrorBook, GetErrorBook, SaveErrorBookChapter, GetErrorChapters, SaveChapterMetaToDB} from "../utils/offline.book.helpers";
import { cleanBookUpdates, cleanChapterUpdates, syncRemoteBookWithLocalDB } from "../utils/sync/helper";
import { MyRootBlock } from "../components/Plate/config/typescript";
import { ChapterMatterType, SectionType } from "../types/sidebar";
import { getBookEndnotes, getBookEndnotesByChapter } from "../utils/get-book-endnotes";
import { PdfChapterEndnotes, PdfEndnoteSubheading, PdfSlateEndnote } from "../components/Previewer/print/types";
import { createPartChapter, createVolumeChapter, updateVolumeChapterMeta } from "../utils/volumes";
import { getAllChildrenIds } from "../utils/sidebar";
import { CHAPTER_TITLE_HIGHEST_SCALE, ChapterTypesWithoutEditor, getLastDescendentIndex } from "../utils/chapter";
import { getPlateChapterBodyForChapter, initializeNewYChapter, addYChapterContentToExistingChapter, replaceYChapterContent } from "../utils/y";
import { GetPlateChapterBodybyIdResponse } from "../types/sync";
import { syncBookBaseData, syncBookData } from "../utils/sync";
import SideMenuStore from "./SideMenu";
import ShelfStore from "./Shelf";
import { bookSyncWebSocketStore, chapterStore, authStore } from ".";
import { Operation } from "slate";

export class BookStore {
  online = navigator.onLine;
  chapterLoading = false;
  failedBookLoading = false;
  toggleName = "";
  searchLevel = "chapter";
  extras: IChapterStore.ChapterExtra[] = [];
  searchParams : IBookStore.SearchParams = {
      q: "",
      caseSensitive: false,
      wholeWord: false
  };
  replaceQuery = "";
  replaceParams: IBookStore.ReplaceParams = {
    text: "",
    all: false
  }
  searchStep = 0;
  searchMatchedRanges: IBookStore.DecoratedRange[] = [];
  syncClose = true;
  allBody = {
    active: false,
    numbered: false,
    beginOn: ""
  }
  chapterOffContentMap = new Map();
  book: IBookStore.ExpandedBook = initBook;
  chapterTemplate: IChapterTemplateBase = initTemplate;
  body : MyRootBlock[] = [];
  changeCount = 0;
  wordsCount = 0;
  wordCountType: IBookStore.wordCountType = "chapter";
  errorBookId: string[] = [];
  errorChapterId: string[] = [];
  errorBooks: IBookStore.ErrorBook[] = [];
  errorChapters: IBookStore.ErrorChapter[] = [];
  editorCurrentOperation: Operation | undefined = undefined;

  constructor() {
    makeAutoObservable(this, {
      book: observable,
      body: observable.ref,
      wordsCount: observable.struct,
      searchParams: observable.deep,
      searchRange: computed,
      replaceParams: observable.ref
    });

    this.setupBookReactions = this.setupBookReactions.bind(this);
    this.setupEventListeners();
  }

  
  setupBookReactions() {
    reaction(
      () => chapterStore.chapterMeta,
      (updatedChapter) => {
        const chapter = toJS(updatedChapter);
        
        // Check if updatedBook is undefined before accessing properties
        if (!(chapter && chapter._id)) {
          return;
        }

        if(!this.book){
          return;
        }
        
        const book = toJS(this.book);
        const obj = {
          "chapters": this.book.chapters.findIndex(chap => chap._id === updatedChapter._id),
          "frontMatter": this.book.frontMatter.findIndex(fm => fm._id === updatedChapter._id)
        };

        Object.keys(obj).forEach((matter) => {
          const index = obj[matter]; // Index of the matching object in chapters/frontMatter array

          // Skip if index is invalid or the array doesn't exist
          if (index < 0 || !(book[matter] && book[matter].length > 0)) return;

          // Return if book's Id doesn't match the updated chapter's bookId
          if(book._id !== updatedChapter.bookId) return;

          // Create a new array with the updated object
          const updatedMatterArray = [...book[matter]];

          // check for changes, including timestamps, exit if no change
          if(isEqual(updatedMatterArray[index], chapter)) return;

          // Replace the object at the found index
          updatedMatterArray[index] = chapter;

          const updatedBook = {
            ...book,
            [matter]: updatedMatterArray
          };

          //re-assign book
          this.setBook(updatedBook);
          
          // sync book updates if chapter updates
          this.syncBookTimestamps(updatedChapter.bookId);
        });
      }
    );
  }

  get searchRange() {
    return this.searchMatchedRanges[this.searchStep];
  }

  setupEventListeners() {
    window.addEventListener("online", this.updateOnlineStatus);
    window.addEventListener("offline", this.updateOnlineStatus);
  }

  updateOnlineStatus = () => {
    this.online = navigator.onLine;
  };

  generateItemId = ():string => generateRandomString(16);

  setBook = (book: IBookStore.ExpandedBook): void => {
    this.book = book;
  }

  updateBook = (updates: Partial<IBookStore.ExpandedBook>) => {
    if(updates) {
      this.book = { ...this.book, ...updates};
    }
  }

  setSearchParams = (params: IBookStore.SearchParams): void => {
    this.searchParams = params;
  }

  setReplaceParams = (params: IBookStore.ReplaceParams): void => {
    this.replaceParams = params;
  }

  setReplaceQuery= (term: string): void => {
    this.replaceQuery = term;
  }

  setSearchLevel = (s: string): void => {
    this.searchLevel = s;
  }
  
  setSearchStep = (step: number) => {
    this.searchStep = step;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setSearchMatchedRanges = (r: IBookStore.DecoratedRange[]) => {
    this.searchMatchedRanges = r;
  }

  setExtras = (list: IChapterStore.ChapterExtra[]) => {
    this.extras = list;
  }

  isRecentUpdate(current: string, updated: string): boolean {
    const currentTime = new Date(current);
    const updatedTime = new Date(updated);
    
    // Calculate the difference in hours
    const timeDifference = (currentTime.getTime() - updatedTime.getTime()) / (1000 * 3600);

    return timeDifference <= 24;
  }
  
  handleSearchNext = () => {
    if (this.searchStep >= this.searchMatchedRanges.length - 1) {
        this.setSearchStep(0);
    } else {
        this.setSearchStep(this.searchStep + 1);
    }
  };

  handleSearchPrevious = () => {
    if (this.searchStep === 0) {
        this.setSearchStep(this.searchMatchedRanges.length - 1);
    } else {
        this.setSearchStep(this.searchStep - 1);
    }
  };

  // update book
  handleReplaceForBooks = async (term: string) => {
    const chapterIds = this.book.chapterIds; 
    const promises = chapterIds.map(async (chapterId) => {
      const chapterChildren = (await getPlateChapterBodyForChapter(chapterId)).chapterBody;
      const occurrences = doKeywordCount(chapterChildren, this.searchParams);
  
      // Only do the replace if occurences are positive for the chapter
      if (occurrences) {
        const updatedContent = doSearchReplace(chapterChildren, this.searchParams, term);
  
        return replaceYChapterContent(chapterId, updatedContent);
      }
    });
  
    await Promise.all(promises);
  
    this.setExtras([]);
  };

  doSearchQuery = async () => {
    const chapters = await getChaptersFromJSONCache(this.book.chapterIds);
    const list = chapters.map(d => ({id: d._id, extra: doKeywordCount(d.children, this.searchParams)}));
    this.setExtras(list);
  }

  doSearchSetCount = () => {
    if(this.searchLevel === "book" && this.searchParams.q.length > 0){
        this.debounceSearchQuery();
    } else {
        this.setExtras([]);
    }
  };

  resetReplaceParams = () => {
    this.setReplaceQuery("");
    this.setReplaceParams({
      text: "",
      all: false
    });
  };

  resetSearchParams = () => {
    this.setSearchMatchedRanges([]);
    this.setSearchStep(0);
    this.setSearchParams({
      q: "",
      caseSensitive: false,
      wholeWord: false
    });
  };

  resetSearchAndReplace = () => {
    this.resetSearchParams();
    this.resetReplaceParams();
  };

  debounceSearchQuery =  debounce(this.doSearchQuery, 1500);

  debounceSearch =  debounce(this.setSearchParams, 1000);

  // Error Visualization
  setErrorBook = async (book_id: string): Promise<void> => {
    this.errorBookId.push(book_id);
    await SaveErrorBook(this.errorBookId);
  };

  // Error Visualization
  setHomeErrorBook = (err: IBookStore.ErrorBook[]): void => {
    this.errorBooks = err;
  }

  // Error Visualization
  setFailedBookLoading = (loading: boolean): void => {
    this.failedBookLoading = loading;
  }

  // Error Visualization
  getErrorBook = async () : Promise<IBookStore.ErrorBook[] | undefined> => {
    this.setFailedBookLoading(false);
    const failedBook = await GetErrorBook();

    if(failedBook) {
      this.setFailedBookLoading(true);
      this.setHomeErrorBook(failedBook);
    }
    return failedBook;
  }

  // Error Visualization
  setErrorChapter = async (chapter_id: string, book_id: string): Promise<void> => {
    this.errorChapterId.push(chapter_id);
    const allE = {
      _chapterId: chapter_id,
      _bookId: book_id
  };
    await SaveErrorBookChapter(allE);
  }

  // Error Visualization
  setHomeErrorChapter = (err: IBookStore.ErrorChapter[]): void => {
    this.errorChapters = err;
  }

  // Error Visualization
  getErrorChapter = async () : Promise<IBookStore.ErrorChapter[] | undefined> => {
    this.setFailedBookLoading(false);
    const failedChapters = await GetErrorChapters();

    if(failedChapters) {
      this.setFailedBookLoading(true);
      this.setHomeErrorChapter(failedChapters);
    }

    return failedChapters;
  }

  setAllBody = (allb: IChapterStore.AllBodyChapter) : void => {
    this.allBody = allb;
  }
  
  setBody = (body: MyRootBlock[]): void => {
    this.body = body;
  }

  setWordsCount = (wordsCount: number): void => {
    this.wordsCount = wordsCount;
  }

  setWordCountType = (type: IBookStore.wordCountType): void => {
    this.wordCountType = type;
  }

  setEditorCurrentOperation = ( operation : Operation): void => {
    this.editorCurrentOperation = operation;
  }

  setChapterLoading = (loading: boolean): void => {
    this.chapterLoading = loading;
  }

  // goal settiing
  setToggleButtonName = ( name: string): void => {
  this.toggleName = name;
  }

  setSyncErrorMessage = (close: boolean): void => {
    this.syncClose = close;
  }

  setChangeCount = (): void => {
    this.changeCount = this.changeCount + 1;
  }

  getCurrentBookId = (): string => {
    return this.book._id;
  }

  getEditorCurrentOperation = (): Operation | undefined => {
    return this.editorCurrentOperation;
  }

  getCurrentBookAbilities = () => {
    return {
      newBook: this.book.isLocal,
      currentBookToken: this.book.abilities,
      newBookToken: authStore.newBookToken,
    };
  }

  chapterSetOfflineContent = (chapterId: string, hasOfflineContent: boolean) => {
    this.chapterOffContentMap.set(chapterId, hasOfflineContent);
  }

  chapterHasOfflineContent = (chapterId: string): undefined | boolean => {
    return this.chapterOffContentMap.get(chapterId);
  }

  // chapter template library
  setChapterTemplate = (chapterTemplate: IChapterStore.IChapterTemplateBase): void => {
    this.chapterTemplate = chapterTemplate;
  }

  next = (id: string, chapters: IChapterStore.ChapterMeta[]): void => {
    const index = chapters.map((d) => d._id).indexOf(id);

    if (chapters.length === 1) {
      this.getAndSetCurChapter(this.book.frontMatterIds[0]);
      return;
    }

    if (index < chapters.length - 1)
      this.getAndSetCurChapter(chapters[index + 1]._id);
    else this.getAndSetCurChapter(chapters[index - 1]._id);
  };

  getChapterMatterById = (id: string): ChapterMatterType => {
    if (this.book.frontMatterIds.includes(id)) return "frontMatter";
    return "body";
  };

  getAndSetCurBook = async (bookId: string): Promise<IBookStore.ExpandedBook | undefined> => {
    this.setChapterLoading(true);

    let book;

    /*
      Mount directly from indexedb db when offline
      Otherwise do book sync on mount
    */
    if (this.online) {
      book = await syncBookData(bookId);
    } else {
      book = await GetBookFromDB(bookId, true) as IBookStore.ExpandedBook | undefined;
    }

    if(book){
      this.setBook(book); 
    }

    this.setChapterLoading(false);

    return book;
  }

  resetCurrentBook = () => {
    if(this.book._id) {
      this.getAndSetCurBook(this.book._id);
    }
  };

  resetState = () => {
    this.book = initBook;
  };

  getAndSetCurChapter = async (chapterId: string): Promise<void> => {
    const chapterMeta = await GetChapterMetaFromDB(chapterId);
    const chapterBody = (await getPlateChapterBodyForChapter(chapterId)).chapterBody;
    if (chapterMeta) {
      chapterStore.setChapterBody(chapterBody);
      chapterStore.setChapterMeta(removeKey(chapterMeta, "children") as IChapterStore.ChapterMeta);
    }
  };

  // Chapter Template Library
  getAndSetChapterTemplate = async (templateId: string): Promise<void> => {
    this.setChapterLoading(true);
    const template = await GetChapterTemplates(templateId);
    if(template) {
      chapterStore.setChapterBody(template.children);
      this.setChapterTemplate(template);
      const {_id, bookId="", image, subtitle, title, titleScale, startOn="any", type, index=0} = template;
      chapterStore.setChapterMeta({_id, bookId, image, subtitle, title, titleScale, startOn, type, index});
      this.setChapterLoading(false);
    }
  }

  private compileChapter = async (
    chapterId: string,
    shouldSync = false
  ): Promise<IChapterStore.CompileChapterResponse> => {
    const chapterMeta = await GetChapterMetaFromDB(chapterId);
    if (!chapterMeta)
      throw new Error(
        "Could not find chapter meta in IDB for the given chapterId"
      );
    const chapterBodyResponse = await getPlateChapterBodyForChapter(
      chapterId,
      shouldSync
    );
    return {
      chapter: { ...chapterMeta, children: chapterBodyResponse.chapterBody },
      isClientAlreadyInSync: chapterBodyResponse.isClientAlreadyInSync
    };
  };

  /**
   * @param chapterIds An array of chapterIds for which the full chapters need to be returned
   * @returns A list of full chapters containing the chapter metas and the chapter bodies
   */

  private isValidChapter = (chapterId: string) => {
    return !(this.book.deletedChapterIds).includes(chapterId);
  };

  getChapterById = async (
    chapterIds: string[]
  ): Promise<IChapterStore.Chapter[]> => {
    const validChapters = chapterIds.filter(chapter => this.isValidChapter(chapter));
    const promises: Promise<IChapterStore.CompileChapterResponse>[] = [];
    for (const chapterId of validChapters) {
      promises.push(this.compileChapter(chapterId));
    }
    const compiledChapters = await Promise.all(promises);
    return compiledChapters.map((response) => response.chapter);
  };

  getChaptersForExports = async (
    chapterIds: string[],
    shouldSync = false
  ): Promise<IChapterStore.ExportChapter[]> => { 
    const promises: Promise<IChapterStore.CompileChapterResponse>[] = [];
    const validChapters = chapterIds.filter(chapter => this.isValidChapter(chapter));
    for (const chapterId of validChapters) {
      promises.push(this.compileChapter(chapterId, shouldSync));
    }
    const compiledChapters = await Promise.all(promises);
    const exportChapters:IChapterStore.ExportChapter[] = [];
    compiledChapters.map((response) => {
      const shouldRefreshCache = shouldSync ? !response.isClientAlreadyInSync : false;
      const refreshCacheEvent = shouldRefreshCache ? "chapter-contents-change" : undefined;
      exportChapters.push({
        chapter: response.chapter,
        shouldRefreshCache,
        refreshCacheEvent
      });
    });
    return exportChapters;
  };


  // Atomic sync functions
  syncChapterMetaChangesToServer = async (bookId: string, chapterId: string, updates: Partial<IChapterStore.ChapterMeta>): Promise<void> => {
    const newUpdates = cleanChapterUpdates(updates);

    try {
      const { timestamp } = await AtticusClient.PatchChapter(bookId, chapterId, newUpdates);

      await Promise.all([
        UpdateChapterMeta(chapterId, {
          lastSuccessfulSync: timestamp,
          allChangesSynced: true,
        }),
        this.syncBookTimestamps(bookId),
      ]);
    } catch (error: any) {
      console.error(error);

      if (error.message !== "Network Error") {
        this.saveSpanshot(bookId);
        Modal.destroyAll();

        if (this.syncClose) {
          this.countDown();
        }
      }
    }
  }

  // Chapter Template Library
  syncChapterTemplateChangesToServer = async (templateId: string, updates: Partial<IChapterStore.IChapterTemplateBase>): Promise<void> => {
    try {
      await AtticusClient.PatchChapterTemplate(templateId, updates);
      const batchPromise: Promise<unknown>[] = [];
      batchPromise.push(UpdateChapterTemplateInDB(templateId, { ...updates, allChangesSynced: false }));
      await Promise.all(batchPromise);
    } catch (e: any) {
      if (e.message !== "Network Error"){
        this.saveSpanshot(templateId);
        Modal.destroyAll();
        if(this.syncClose == true)
        this.countDown();
      }
      console.log(e);
    }
  }

  countDown = () => {
    this.setSyncErrorMessage(true);
    const secondsToGo = 4;
    const modal = Modal.error({
      title: "Oh no! We werent able to save your recent work to our server.",
      closable: true,
      okButtonProps: {
        style: {
          display: "none",
        },
      },
      width: 600,
      content: (
        <div>
        <h5>Use the link below to learn more about why this might happen and what you can do to resolve the error and save your work.</h5>
        <a target="_blank" href='https://www.atticus.io/troubleshooting-synching-errors/' rel="noreferrer">
            https://www.atticus.io/troubleshooting-synching-errors/
        </a>
        </div>
        ),
    });

    setTimeout(() => {
      modal.destroy();
      this.setSyncErrorMessage(false);
    }, secondsToGo * 1000);
  }

  syncNewChapterToServer = async (newChapter: IChapterStore.ChapterMeta): Promise<void> => {
    try {
      const { lastSuccessfulSync, allChangesSynced, ...remoteChapter } = newChapter;
      const lastSyncAt = await AtticusClient.PutChapter(remoteChapter);

      await UpdateChapterMeta(newChapter._id, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });
      await this.syncBookTimestamps(newChapter.bookId);
    } catch (e: any) {
      console.log(e.message);
    }
  }

  syncDeleteChapterToServer = async (bookId: string, chapterId: string): Promise<void> => {
    try {
      const { timestamp } = await AtticusClient.DeleteChapter(bookId, chapterId);
      if(timestamp) {
        this.syncBookTimestamps(bookId);
      }
    } catch (e: any) {
      throw new Error("Error syncDeleteChapterToServer");
    }
  }

  syncBookUpdates = async (bookId: string, changes: Partial<IBookStore.ExpandedBook>): Promise<void> => {
    const updates = cleanBookUpdates(changes);

    //set flag for book sync
    const { setIsBookLocalUpdate } = bookSyncWebSocketStore;
    setIsBookLocalUpdate(true);
    
    let timeUpdates : Partial<IBookStore.Book> = {
      allChangesSynced: false,
      lastUpdateAt: new Date().toISOString(),
    };

    if(this.online){
      try {
        const { timestamp } = await AtticusClient.PatchBook(bookId, updates);
  
        timeUpdates = timestamp ? {
          lastSuccessfulSync: timestamp,
          allChangesSynced: true,
          lastUpdateAt: timestamp,
        } : {};
  
      } catch (e: any) {
        console.log("Book could not be updated to server", e.message);
      }
    }

    await this.updateBookToDB(
      bookId,
      {
        ...updates,
        ...timeUpdates
      },
      params => this.updateBook(params)
    );
  }

  syncBookTimestamps = (bookId: string) => this.syncBookUpdates(bookId, {})

  updateBookToDB = async (bookId: string, changes: Partial<IBookStore.Book>, callback?: (changes: Partial<IBookStore.Book>) => any): Promise<void> => {
    await UpdateBookInDB(bookId, changes);

    if(callback){
      callback(changes);
    }
  }

  updateChapterBodyEditorOnChange = async(id: string, body: MyRootBlock[]): Promise<void> => {
    if (chapterStore.chapterMeta._id === id) {
      chapterStore.setChapterBody(body);
    }
  }

  // Chapter Template Library Debounced atomic sync functions
  debouncedSyncChapterTemplateChangesToServer = debounce(this.syncChapterTemplateChangesToServer, 1000);

  // Chapter Template Library
  saveChapterTemplateBodyUpdates = async (id: string, body: MyRootBlock[]): Promise<void> => {
    chapterStore.setChapterBody(body);
    // Persist changes
    const allPromises: Promise<void>[] = [];
    allPromises.push(UpdateChapterTemplateInDB(id, { children: body, allChangesSynced: false }));
    await Promise.all(allPromises);
    //ADD CHAPTER TEMPLATE SYNC
    this.debouncedSyncChapterTemplateChangesToServer(this.chapterTemplate._id, { children:  body });

  }

  saveChapterMetaUpdatesAsBulk = async (
    metas: IChapterStore.ChapterMeta[],
    updateChapter = true,
    debounceServerSync = true
  ): Promise<void> => {
    const { setIsChapterLocalUpdate, setIsChapterMetaSave } = bookSyncWebSocketStore;
    const bookId = this.book._id;

    // Helper function to update book sections (frontMatter & chapters)
    const updateSection = (section: any[]) =>
      section.map(item => metas.find(meta => meta._id === item._id) || item);
  
    // Save all chapter meta data in bulk
    SaveChapterMetaToDB(JSON.parse(JSON.stringify(metas)));
  
    await this.syncSections({
      frontMatter: toJS(updateSection(this.book.frontMatter)),
      frontMatterIds: toJS(this.book.frontMatterIds),
      chapters: toJS(updateSection(this.book.chapters)),
      chapterIds: toJS(this.book.chapterIds),
    });
  
    // Update local store if required
    if (updateChapter) {
      metas.forEach(chapterStore.setChapterMeta);
    }
  
    // Sync chapter meta updates to remote server
    const syncFunction = debounceServerSync
      ? this.debouncedSyncChapterChangesToServer
      : this.syncChapterMetaChangesToServer;
  
    await Promise.all(
      metas.map(meta => syncFunction(bookId, meta._id, cleanChapterUpdates(meta)))
    );
  
    // Set sync state
    setIsChapterLocalUpdate(true);
    setIsChapterMetaSave(true);
  };


  saveChapterMetaUpdates = async (
    meta: IChapterStore.ChapterMeta,
    updateChapter = true,
    debounceServerSync = true,
    updatingLastUpdateAt = false
  ): Promise<void> => {
    const { setIsChapterLocalUpdate, setIsChapterMetaSave } = bookSyncWebSocketStore;
    const bookId = this.book._id;

    // Helper function to update book sections (frontMatter & chapters)
    const updateSection = (section: IChapterStore.ChapterMeta[]) =>
      section.map(d => (d._id === meta._id ? { ...d, ...meta } : d));
  
    // Prepare update promises
    const updatePromises = [
      UpdateChapterMeta(meta._id, {
        title: meta.title,
        titleScale: meta.titleScale,
        subtitle: meta.subtitle,
        image: meta.image,
        type: meta.type,
        numbered: meta.numbered,
        includeIn: meta.includeIn,
        startOn: meta.startOn,
        templateId: meta.templateId,
        allChangesSynced: false,
        fullpageImage: toJS(meta.fullpageImage),
        parentChapterId: meta.parentChapterId,
        configuration: meta.configuration,
        volume: toJS(meta.volume),
        toc: toJS(meta.toc),
      }),
      await this.syncSections({
        frontMatter: toJS(updateSection(this.book.frontMatter)),
        frontMatterIds: toJS(this.book.frontMatterIds),
        chapters: toJS(updateSection(this.book.chapters)),
        chapterIds: toJS(this.book.chapterIds),
      })
    ];
  
    await Promise.all(updatePromises);
  
    // Prevent updating chapter meta if updating lastUpdateAt on a different chapter
    if (updatingLastUpdateAt && meta._id !== chapterStore.chapterMeta._id) {
      updateChapter = false;
    }
  
    if (updateChapter) {
      chapterStore.setChapterMeta(meta);
    }
  
    // Sync to remote server
    const syncFunction = debounceServerSync
      ? this.debouncedSyncChapterChangesToServer
      : this.syncChapterMetaChangesToServer;
  
    await syncFunction(bookId, meta._id, cleanChapterUpdates(meta));
  
    // Update sync status
    setIsChapterLocalUpdate(true);
    setIsChapterMetaSave(true);
  };

  addNewChapter = async (
    props: {
      section: SectionType,
      type: IChapterStore.ChapterType,
      chapter?: Partial<IChapterStore.ChapterMeta>,
      body?: MyRootBlock[],
      insertAtEnd?: boolean,
      shouldInitiateYChapter?: boolean
    }
  ): Promise<IChapterStore.ChapterMeta | void> => {
    if (!this.book._id) return;
    const { section, type, chapter, body, insertAtEnd, shouldInitiateYChapter = true } = props;
    const chapterBody = body ? cloneDeep(body) : initBody;
    const chapterId = chapter?._id ? chapter._id : this.generateItemId();
    let chps: Array<string> = [];

    if (section === "frontMatter") {
      chps = this.book.frontMatterIds;
    }

    if (section === "body") {
      chps = this.book.chapterIds;
    }

    const lastDescendentIndex = getLastDescendentIndex(chapterStore.chapterMeta._id, this.book.chapters);
    const parentChapter = this.book.chapters.find(d => d._id === chapterStore.chapterMeta.parentChapterId);
    const insertIntoVolume = parentChapter?.type === "volume" || chapterStore.chapterMeta.type === "volume";
    let indx = insertAtEnd ? chps.length : (lastDescendentIndex + 1);

    // When the section is frontMatter & needs to insert into volume, insert at end of volume frontMatter
    if (section === "frontMatter" && insertIntoVolume) {
      const volumeChapter = parentChapter?.type === "volume" ? parentChapter : chapterStore.chapterMeta;
      const lastDescendentIndex = getLastDescendentIndex(volumeChapter._id, this.book.chapters);
      indx = lastDescendentIndex - (volumeChapter.volume?.bodyMatterIds.length || 0);
    }

    if (indx < 1) indx = chps.length + 1;

    const _c : IChapterStore.ChapterMeta = {
      _id: chapterId,
      bookId: this.book._id,
      title: type === "chapter" ? (chapter?.title? chapter.title : `Chapter ${indx}`) : getPresentableChapterType(type),
      subtitle: "",
      image: "",
      titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
      type: type,
      index: indx,
      startOn: "any",
      allChangesSynced: false,
      parentChapterId: ["volume", "part"].includes(chapterStore.chapterMeta.type) ? chapterStore.chapterMeta._id : parentChapter?._id,
    };

    const newChapterMeta: IChapterStore.ChapterMeta = chapter ? {
      ..._c,
      ...chapter
    } : _c;

    let updatedVolumeChapterMeta: IChapterStore.ChapterMeta | undefined;
    if (insertIntoVolume) {
      const volumeChapter = parentChapter?.type === "volume" ? parentChapter : chapterStore.chapterMeta;
      updatedVolumeChapterMeta = { ...volumeChapter };
      const volumeChapterIndex = this.book.chapters.findIndex(d => parentChapter && d._id ===  parentChapter._id);
      const frontMatterCount = volumeChapter.volume?.frontMatterIds ? volumeChapter.volume?.frontMatterIds.length : 0;
      const insertIndexInVolume = indx - volumeChapterIndex - 1;
      if (updatedVolumeChapterMeta.volume ===  undefined)
        throw ReferenceError();
      if (insertIndexInVolume <= frontMatterCount) {
        updatedVolumeChapterMeta.volume.frontMatterIds.splice(insertIndexInVolume, 0, chapterId);
      } else {
        updatedVolumeChapterMeta.volume.bodyMatterIds.splice(insertIndexInVolume - frontMatterCount, 0, chapterId);
      }
    }

    let frontMatter = {
      all: toJS(this.book.frontMatter),
      ids: toJS(this.book.frontMatterIds)
    };

    let chapters = {
      all: toJS(this.book.chapters),
      ids: toJS(this.book.chapterIds)
    };

    // frontMatter without inserting into volume is in book frontMatter
    if (section === "frontMatter" && !insertIntoVolume) {
      frontMatter = {
        all: this.addAfter(frontMatter.all, indx, newChapterMeta),
        ids: this.addAfter(frontMatter.ids, indx, newChapterMeta._id),
      };
    }

    // both body OR frontMatter with inserting into volume is going to be inside body chapters
    if (section === "body" || section === "frontMatter" && insertIntoVolume) {
      chapters = {
        all: this.addAfter(chapters.all, indx, newChapterMeta),
        ids: this.addAfter(chapters.ids, indx,  newChapterMeta._id)
      };

      if (updatedVolumeChapterMeta !== undefined) {
        chapters.all.splice(chapters.all.findIndex(d => d._id === updatedVolumeChapterMeta?._id), 1, updatedVolumeChapterMeta);
      }
    }

    const bookSectionUpdates = {
      frontMatter: frontMatter.all,
      chapters: chapters.all,
      frontMatterIds: frontMatter.ids,
      chapterIds: chapters.ids,
    };

    // Due to the lag awaiting a reponse from API Calls, we re-set the book here to show the change in the interface instantly
    this.updateBook(bookSectionUpdates);

    /**
     *  avoid focusing the newly created chapter for offline chapters
     */
    if(chapterId.indexOf("offline") === -1){
      chapterStore.setChapterBody(chapterBody);
      chapterStore.setChapterMeta(newChapterMeta);
    }

    // array to store promises for db updates
    const dbPromises: Promise<unknown>[] = [];
    if (updatedVolumeChapterMeta !== undefined) {
      dbPromises.push(db.chapterMetas.update(updatedVolumeChapterMeta._id, { volume: toJS(updatedVolumeChapterMeta.volume) }));
    }
    dbPromises.push(db.chapterMetas.add(newChapterMeta));
    if(shouldInitiateYChapter){
      dbPromises.push(initializeNewYChapter(chapterId, chapterBody));
    }
    await Promise.all(dbPromises);

    // array to store promises for chapter sync to server
    const serverSyncPromises: Promise<unknown>[] = [];

    await this.syncNewChapterToServer(newChapterMeta);

    if (updatedVolumeChapterMeta !== undefined) {
      serverSyncPromises.push(this.syncChapterMetaChangesToServer(this.book._id, updatedVolumeChapterMeta._id, { volume: toJS(updatedVolumeChapterMeta.volume) }));
    }

    serverSyncPromises.push(
      this.syncSections(bookSectionUpdates)
    );

    await Promise.all(serverSyncPromises);
    return newChapterMeta;
  }

  addAfter = (array, index, newItem) => {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
  }

  deleteChapter = async (id: string, next?: boolean): Promise<void> => {
    if (!this.book) return;

    const {chapters, deletedChapterIds = []} = this.book;

    const parentVolumeIndex = chapters.findIndex(
      ({ type, volume }) =>
        type === "volume" &&
        volume &&
        [...volume.bodyMatterIds, ...volume.frontMatterIds].includes(id)
    );

    if (parentVolumeIndex !== -1) {
      const volumeMeta = chapters[parentVolumeIndex];

      if (volumeMeta.volume) {
        const updatedVolumeMeta: ChapterMeta = {
          ...volumeMeta,
          volume: {
            ...volumeMeta.volume,
            frontMatterIds: volumeMeta.volume.frontMatterIds.filter(cId => cId !== id ),
            bodyMatterIds: volumeMeta.volume.bodyMatterIds.filter(cId => cId !== id ),
          },
        };
        chapters.splice(parentVolumeIndex, 1, updatedVolumeMeta);
      }
    }

    const newBook = {
      ...this.book,
      ...({ frontMatterIds: toJS(this.book.frontMatterIds.filter(c => c !== id)) }),
      ...({ frontMatter: toJS(this.book.frontMatter.filter(c => c._id !== id)) }),
      ...({ chapterIds: toJS(this.book.chapterIds.filter(c => c !== id)) }),
      ...({ chapters: toJS(chapters.filter(c => c._id !== id)) }),
      ...({ deletedChapterIds: toJS([...deletedChapterIds, id]) }),
    };

    const partialBookUpdates: Partial<IBookStore.Book> = {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      deletedChapterIds: newBook.deletedChapterIds,
      allChangesSynced: false
    };

    // Update store to reflect the interface
    this.setBook(newBook);

    try {
      await this.syncDeleteChapterToServer(newBook._id, id);

      if (next) {
        this.next(id, toJS(this.book.chapters));
      }

      const allPromises: Promise<unknown>[] = [];
      allPromises.push(db.chapterMetas.where("_id").equals(id).delete());
      allPromises.push(
          this.updateBookToDB(
            newBook._id, 
            partialBookUpdates,
            () => this.setBook(newBook)
          )
        );

      if (parentVolumeIndex !== -1) {
        allPromises.push(db.chapterMetas.update(chapters[parentVolumeIndex]._id, { volume: toJS(chapters[parentVolumeIndex].volume) }));
        allPromises.push(this.syncChapterMetaChangesToServer(this.book._id, chapters[parentVolumeIndex]._id, { volume: toJS(chapters[parentVolumeIndex].volume) }));
      }

      await Promise.all(allPromises);
    } catch (e: any) {
      console.log(e);
      alert("Error deleting chapter");
    }
  }

  sortChapters = (frontMatter: IChapterStore.ChapterMeta[], chapters: IChapterStore.ChapterMeta[]): Promise<void> => this.syncSections({
    frontMatter: toJS(frontMatter),
    chapters: toJS(chapters),
    frontMatterIds: toJS(frontMatter.map(d => d._id)),
    chapterIds: toJS(chapters.map(d => d._id))
  });
  

  syncSections = async ({
    chapters,
    frontMatter,
    chapterIds,
    frontMatterIds
  }: {
    chapters: IChapterStore.ChapterMeta[],
    frontMatter: IChapterStore.ChapterMeta[],
    chapterIds: string[]
    frontMatterIds: string[],
  }) => {
    this.updateBook({
      chapters,
      frontMatter
    });
    await this.syncBookUpdates(this.book._id, {
      chapterIds,
      frontMatterIds
    });
  }

  orderByIndex = (book: IChapterStore.ChapterMeta[]) => {
    return toJS(book).sort((a, b) => a.index - b.index).map((d, i) => ({
      ...d,
      index: i
    }));
  };

  mergeChapter = async (section: SectionType, id: string): Promise<void> => {
    let index = -1;
    let chapterToPreserve: IChapterStore.ChapterMeta | null = null;
    let chapterToDelete: IChapterStore.ChapterMeta | null = null;

    if (section === "frontMatter") {
      index = findIndex(this.book.frontMatter, { "_id": id });
      chapterToPreserve = this.book.frontMatter[index];
      chapterToDelete = this.book.frontMatter[index + 1];
    }

    if (section === "body") {
      index = findIndex(this.book.chapters, { "_id": id });
      chapterToPreserve = this.book.chapters[index];
      chapterToDelete = this.book.chapters[index + 1];
    }

    if (chapterToPreserve && chapterToDelete) {
      const chapterBodyToMerge = (await getPlateChapterBodyForChapter(chapterToDelete._id, true)).chapterBody;
      const titleNodes = [{ type: "h2", children: [{ text: chapterToDelete.title }] }] as MyRootBlock[];
      if (chapterToDelete.subtitle?.length) {
        titleNodes.push({ type: "h2", children: [{ text: chapterToDelete.subtitle }] });
      }
      const finalChapterBodyToMerge = [...titleNodes, ...chapterBodyToMerge];
      await addYChapterContentToExistingChapter(id, finalChapterBodyToMerge);
      await this.deleteChapter(chapterToDelete._id);
      this.setChangeCount();
    } else {
      alert("No next Chapter");
    }
  }

  getIdsbyMatter = (m: Array<"front" | "body">) => {
    let ids : Array<string> = [];
    if (m.includes("front"))
      ids = [...ids, ...this.book.frontMatterIds];
    if (m.includes("body"))
      ids = [...ids, ...this.book.chapterIds];

    return ids;
  }

  getBookBodies = async () => {
    const chapterIds = this.book.chapterIds.filter(d => d !== chapterStore.chapterMeta._id);
    const promises:Promise<GetPlateChapterBodybyIdResponse>[] = [];
    chapterIds.forEach(chapterId => {
      promises.push(getPlateChapterBodyForChapter(chapterId));
    });
    const allChapterBodies = await Promise.all(promises);
    const totalWordCound = allChapterBodies.reduce((total, getChapterBodyResponse) => {
      return total + getWordsCount(getChapterBodyResponse.chapterBody);
    }, 0);

    return {
      words: totalWordCound,
      ids: this.book.chapterIds
    };
  }

  getFullBookWordCount = async(): Promise<number> => {
    // Word count of all the chapters of the current book except the currently being edited chapter.
    const allChaptersExcludingCurrentlyActiveWordCount = await this.getBookBodies();

    const currentChapterWordCount = getWordsCount(chapterStore.chapterBody);

    const totalInitialWordCount =
      allChaptersExcludingCurrentlyActiveWordCount.words +
      currentChapterWordCount;

    return totalInitialWordCount;
  }

  debouncedSyncChapterChangesToServer = debounce(this.syncChapterMetaChangesToServer, 1000);
  /**
   * same as debouncedSyncChapterChangesToServer but has a longer debounce period
   * saved the chapter body in mongodb when its too large to be sent over sockets
   */
  debouncedSyncChapterBodyToServer = debounce(this.syncChapterMetaChangesToServer, 3000);
  debouncedUpdateChapterBodyEditorOnChange = debounce(this.updateChapterBodyEditorOnChange, 1000);
  debouncedSaveChapterMetaUpdates = debounce(this.saveChapterMetaUpdates, 400);
  debouncedSaveChapterTemplateBodyUpdates = debounce(this.saveChapterTemplateBodyUpdates, 600);

  importChapters = async (fileURL: string, insertIntoVolume: boolean): Promise<string | undefined> => {
    try {
      const response = await AtticusClient.ImportChapters({
        url: fileURL,
        bookId: this.book._id,
        insertIntoVolume: insertIntoVolume,
      });
      await syncRemoteBookWithLocalDB(this.book._id);
      return response.bookId;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  };

  compilePlateChapterFromChapterId = async (chapterId: string): Promise<IChapterStore.Chapter | undefined> => {
    const chapterMeta = await db.chapterMetas.get(chapterId);
    const isChapterWithEditor = !ChapterTypesWithoutEditor.includes(
      chapterMeta?.type as IChapterStore.ChapterTypeWithoutEditor
    );
    const chapterBody = isChapterWithEditor? (await getPlateChapterBodyForChapter(chapterId, false)).chapterBody : [];
    if(!chapterMeta) return;
    return {...chapterMeta, children:chapterBody || []};
  }

  exportBook = async (bookId: string, type: "pdf" | "epub" | "docx"): Promise<ExportResponse | undefined> => {
    await syncBookBaseData(bookId);
    const book = await GetBookFromDB(bookId, false) as IBookStore.ExpandedBook | undefined;
    if(!book) return;

    const allChapterIds = [...book.frontMatterIds, ...book.chapterIds];

    const chapters:IChapterStore.Chapter[] = [];
    for(const chapterId of allChapterIds){
      const chapter = await this.compilePlateChapterFromChapterId(chapterId);
      if(chapter) chapters.push(chapter);
    }
    const compiledBook : IBookStore.ExpandedBook = {...book, chapters};
    const resp = await AtticusClient.ExportBook(bookId, type, compiledBook);
    return resp;
  }

  getBookSnapshot = async (bookId: string, isOffline?: boolean): Promise<IBookStore.SnapshotBook | undefined> => {
    const book = await GetBookFromDB(bookId, true) as IBookStore.ExpandedBook | undefined;

    if (!book) return;
    
    if (!book.userId) {
      const currentUser = authStore.getCurrentUser();
      book.userId = currentUser?._id;
    }
    // Helper function to fetch and process chapter data
    const processChapters = async (chapters: IChapterStore.ChapterMeta[]): Promise<IChapterStore.Chapter[]> => {
      return Promise.all(
        chapters.map(async (chapter) => {
          const chapterBodyResponse = await getPlateChapterBodyForChapter(chapter._id, !isOffline);
          return { ...chapter, children: chapterBodyResponse.chapterBody } as IChapterStore.Chapter;
        })
      );
    };

    // Process front matter and chapters concurrently
    const [frontMatter, chapters] = await Promise.all([
      processChapters(book.frontMatter),
      processChapters(book.chapters),
    ]);

    return { ...book, frontMatter, chapters };
};

  saveSpanshot = async(bookId: string): Promise<boolean> => {
    const snapshot = await this.getBookSnapshot(bookId);

    if (snapshot) {
      await AtticusClient.SaveSnapshot(bookId, JSON.stringify(snapshot));
    }

    return true;
  }

  addNewChapterFromTemplate = async (
    chapterTemplate: IChapterTemplateBase,
    type: IChapterStore.ChapterType,
    section?: string
  ): Promise<void> => {
    if (!this.book._id) return;

    const chapter : Partial<IChapterStore.ChapterMeta> = {
      title: chapterTemplate.title,
      subtitle: chapterTemplate.subtitle,
      image: chapterTemplate.image,
      type: type,
      titleScale: chapterTemplate.titleScale,
      startOn: chapterTemplate.startOn || "any",
      templateId: chapterTemplate._id,
      numbered: chapterTemplate.numbered
    };

    const newChapter = await this.addNewChapter(
      {
        section: section as SectionType,
        type,
        chapter,
      }
    );
    
    if(newChapter) {
      await replaceYChapterContent(newChapter._id, chapterTemplate.children);
    }
  }

  getCurrentStoredBook = (): IBookStore.ExpandedBook => {
    return this.book;
  };

  groupChapters = async (selectedChapIds: string[], groupingMode: "volume" | "part"): Promise<IBookStore.ExpandedBook | null> => {

    const book = this.book;
    if (!book) return null;
    if (selectedChapIds.length <= 0) return null;

    const chapters = toJS(book.chapters);
    const chapterIds = toJS(book.chapterIds);

    // get the first index of the first selected chapter
    const insertPartAt = chapterIds.findIndex(chapId=> selectedChapIds.includes(chapId));
    // generate the id for the new parent chapter
    const parentChapterId = generateRandomString(16);

    const chaptersWithoutSelected = chapters.filter((r) => !selectedChapIds.includes(r._id));
    const childChapters = selectedChapIds.map((e) => ({ ...book.chapters.find((k) => k._id === e)  as IChapterStore.ChapterMeta  }));
    const updatedChildren = childChapters.map(r=>({...r,parentChapterId }));

    const params = {chapterId:parentChapterId, bookId: this.book._id, parentChapterId:childChapters[0].parentChapterId};

    const creationResult = groupingMode === "part" ? createPartChapter(params) : createVolumeChapter({ ...params, chapterIds: selectedChapIds,chapters });

    const { newChapters } = creationResult;

    let updatedChapters = [
      ...chaptersWithoutSelected.slice(0, insertPartAt),
      ...newChapters,
      ...updatedChildren,
      ...chaptersWithoutSelected.slice(insertPartAt),
    ];

    const { metaUpdatedChapters, volumeChapterIndex } = updateVolumeChapterMeta([...updatedChapters], newChapters[0], book._id);

    // need to check specifically for undefined since 0 can returned as index
    if (volumeChapterIndex !== undefined) {
      updatedChapters = metaUpdatedChapters;
    }

    const updatedChapterIds = updatedChapters.map((r) => r._id);

    const newBook = {
      ...this.book,
      chapters: toJS(updatedChapters),
      chapterIds: toJS(updatedChapterIds),
    };

    // Update store to reflect the interface
    this.updateBook({
      chapters: toJS(updatedChapters),
      chapterIds: toJS(updatedChapterIds),
    });

    // update the in-memory book. this will trigger updates to side menu & other components
    chapterStore.setChapterMeta(newChapters[0]);

    // store promises for updates to the local db
    const promises: Promise<unknown>[] = [];

    for (let i = 0; i < newChapters.length; i++) {
      const { children, ...chapterMeta } = newChapters[i];
      promises.push(db.chapterMetas.add(chapterMeta));
      promises.push(initializeNewYChapter(chapterMeta._id, children));
    }

    for (const child of updatedChildren) {
      promises.push(db.chapterMetas.update(child._id, { parentChapterId }));
    }

    if (volumeChapterIndex !== undefined) {
      const updatedVolumeChapter = updatedChapters[volumeChapterIndex];
      promises.push(db.chapterMetas.update(updatedVolumeChapter._id, { volume: updatedVolumeChapter.volume }));
    }

    // wait till all updates to local db are executed
    await Promise.all(promises);

    // array to store promises for chapter sync to server
    const serverSyncPromises: Promise<unknown>[] = [];

    for (let i = 0; i < newChapters.length; i++) {
      serverSyncPromises.push(
        this.syncNewChapterToServer({ ...newChapters[i] })
      );
    }

    // wait till all chapters are synced
    await Promise.all(serverSyncPromises);

    await this.syncBookUpdates(
      book._id, 
      { 
        chapterIds: updatedChapterIds 
      }
    );

    // array to store promises for the chapter updates to the server
    const chapterUpdates: Promise<unknown>[]  = [];

    for (const child of childChapters) {
      // add each update to array so we can batch them
      chapterUpdates.push(this.syncChapterMetaChangesToServer(book._id, child._id, { parentChapterId }));
    }

    if (volumeChapterIndex !== undefined) {
      const updatedVolumeChapter = updatedChapters[volumeChapterIndex];
      chapterUpdates.push(this.syncChapterMetaChangesToServer(book._id, updatedVolumeChapter._id, { volume: updatedVolumeChapter.volume  }));
    }

    await Promise.all(chapterUpdates);

    return newBook;
  }


  deleteChapterGroup = async (chapterId: string, preserveChildren: boolean) :Promise<IBookStore.ExpandedBook|undefined> => {
    if (!this.book) return;
    // parts or volumes can only be in body
    const deletedParentIndex = this.book.chapters.findIndex((c) => c._id === chapterId);
    const deletedParent = toJS(this.book.chapters[deletedParentIndex]);

    // if the chapter is not found or if its not a part or a volume return
    if (!deletedParent || !["part", "volume"].includes(deletedParent.type)) return;

    // mark chapterIds for deletion
    const chaptersToDelete: string[] = [];
    // metadata of the updated chapters
    const updatedChapterMeta:ChapterMeta[] = [];

    // util to filter out deleted chapters
    const dropChapters = (chaps: ChapterMeta[]) =>
      toJS(chaps).filter((c) => {
        if (c._id === chapterId) {
          chaptersToDelete.push(c._id);
          return false;
        }
        // delete toc chapter and title chapters of volumes independent of preserveChildren
        else if (c.parentChapterId === chapterId && (c.type === "toc" || c.type === "title")) {
          chaptersToDelete.push(c._id);
          return false;
        }
        // keep the children if the preserve children flag is true, else drop them
        else if (!preserveChildren && c.parentChapterId === chapterId) {
          chaptersToDelete.push(c._id);
          return false;
        } else {
          return true;
        }
      });

    const frontMatter = dropChapters(this.book.frontMatter);

    // drop the deleted chapters & update the metadata
    const chapters = dropChapters(this.book.chapters).map((c,i,chaps) => {

      const chap:ChapterMeta = { ...c };

      // if the deleted group had a parent (volume) update it's meta
      if (c._id === deletedParent.parentChapterId && c.type === "volume" &&  chap.volume) {
        const childrenIds =  getAllChildrenIds(chaps.slice(i),chap._id);
        // Only body matter of a volume can include a part so we need to update only the bodyMatter
        chap.volume = { ...chap.volume, bodyMatterIds: childrenIds.filter((id) => chap.volume?.frontMatterIds.includes(id)) };
        updatedChapterMeta.push(chap);

      } else if (c.parentChapterId === deletedParent._id) {
        // update the parentChapterIds of the children to the parent of the deleted chapter
        chap.parentChapterId = deletedParent.parentChapterId || this.book._id;
        updatedChapterMeta.push(chap);
      }

      return chap;
    });

    const newBook = {
      ...this.book,
      frontMatterIds: frontMatter.map((r) => r._id),
      frontMatter: frontMatter,
      chapterIds: chapters.map((r) => r._id),
      chapters: chapters,
    };


    // focus the next chapter before propagating any changes
    const newIndex = deletedParentIndex < chapters.length ? deletedParentIndex : chapters.length - 1;

    // update the parentChapterId of the next chapter in indexedDB
    await UpdateChapterMeta(chapters[newIndex]._id, { parentChapterId: chapters[newIndex].parentChapterId });

    this.getAndSetCurChapter(chapters[newIndex]._id);

    this.setBook(newBook);

    const updatePromises: Promise<unknown>[] = [];
    for (let i = 0; i < updatedChapterMeta.length; i++) {
      updatePromises.push(this.saveChapterMetaUpdates(updatedChapterMeta[i], false, false));
    }
    await Promise.all(updatePromises);

    const deletionPromises: Promise<unknown>[] = [];

    for (let i = 0; i < chaptersToDelete.length; i++) {
      deletionPromises.push(db.chapterMetas.where("_id").equals(chaptersToDelete[i]).delete());
      deletionPromises.push(this.syncDeleteChapterToServer(newBook._id, chaptersToDelete[i]));
    }

    deletionPromises.push(
      this.syncBookUpdates(
        newBook._id, 
        { 
          chapterIds: newBook.chapterIds, 
        }
      )
    );

    await Promise.all(deletionPromises);

    return newBook;
  }

  getAllEndNotesOfBook = async (): Promise<(PdfSlateEndnote | PdfEndnoteSubheading)[]> => {
    const { frontMatterIds, chapterIds } = this.getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapters = await this.getChapterById(allChapterIds);
    return getBookEndnotes(chapters);
  };

  getAllEndNotesByChapter = async (): Promise<(PdfChapterEndnotes)[]> => {
    const { frontMatterIds, chapterIds } = this.getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapterData = await this.getChapterById(allChapterIds);
    return getBookEndnotesByChapter(chapterData);
  };

  updateEndnotesChapter = async (updatedTheme: IThemeStore.Theme, theme:  IThemeStore.Theme, refreshCache: (bookId: string, event: IPDFCacheStore.PDFChangeEvent, data?: IPDFCacheStore.RefreshCacheData) => void): Promise<void> => {
    const { properties: styleProps } = theme;
    const { properties: updatedStyleProps } = updatedTheme;
    const isEndnotesChapterNeeded = updatedStyleProps.ePubNotesMode === "END_OF_BOOK" || updatedStyleProps.notesMode === "END_OF_BOOK";
    const book = this.book;
    const endnotesChapters = [...book.frontMatter, ...book.chapters]
      .filter((chapter) => chapter.type === "endnotes");
    const isEndnotesChapterPresent = endnotesChapters.length > 0;

    let includeIn: "all" | "ebook" | "print" | "none" = "none";
    if (updatedStyleProps.ePubNotesMode === "END_OF_BOOK" && updatedStyleProps.notesMode === "END_OF_BOOK") {
      includeIn = "all";
    } else if (updatedStyleProps.ePubNotesMode === "END_OF_BOOK") {
      includeIn = "ebook";
    } else if (updatedStyleProps.notesMode === "END_OF_BOOK") {
      includeIn = "print";
    }

    if (isEndnotesChapterNeeded && !isEndnotesChapterPresent) {
      // we need an endnotes chapter. but it's not available in the book right now
      // so we add new one
      SideMenuStore.resetSelectedChapters();
      const chapter = await this.addNewChapter({
        section: "body",
        type: "endnotes",
        chapter: { includeIn },
        insertAtEnd: true,
      });
      if (chapter) {
        // Update the PDF cache
        const allChapterIds = [...book.frontMatterIds, ...book.chapterIds, chapter._id];
        const chapterData = await this.getChapterById(allChapterIds);
        const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
        refreshCache(book._id, "chapter-add", { "chapter-add": { chapters: chapterCacheData } });
      }
    }

    if (!isEndnotesChapterNeeded && isEndnotesChapterPresent) {
      // we don't need an endnotes chapter, but it is present in the book right now
      // so we delete it. A for loop is used instead of a simple single chapter deletion to
      // ensure that no multiple endnote chapters survive for long in a book, in the case
      // of break of the invariance where only a single endnotes chapter must be there
      for (const chapter of endnotesChapters) {
        await this.deleteChapter(chapter._id);

        const nonEndNoteChapters = book.chapterIds.filter(chapterId => chapterId !== chapter._id);

        const chapterToSwitchTo = nonEndNoteChapters.length > 0
          ? nonEndNoteChapters[0]
          : null;

        if (chapterToSwitchTo !== null) {
          SideMenuStore.selectChapter(chapterToSwitchTo);

          if (SideMenuStore.setFocusedChapter) {
            SideMenuStore.setFocusedChapter(chapterToSwitchTo);
          }
        } else {
          this.getAndSetCurChapter(book.frontMatterIds[0]);
        }

        // Update PDF cache
        const allChapterIds = [...book.frontMatterIds, ...book.chapterIds];
        const chapterData = await this.getChapterById(allChapterIds);
        const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
        refreshCache(book._id, "chapter-delete", { "chapter-delete": { chapterId: chapter._id, chapters: chapterCacheData } });
      }
    }

    if (isEndnotesChapterNeeded && isEndnotesChapterPresent) {
      // if the endnotes chapter is already there, but the note modes have changed, update chapter metadata
      if (styleProps.ePubNotesMode !== updatedStyleProps?.ePubNotesMode || styleProps.notesMode !== updatedStyleProps?.notesMode) {
        for (const chapter of endnotesChapters) {
          await this.debouncedSaveChapterMetaUpdates({ ...chapter, includeIn });
          refreshCache(book._id, "chapter-properties-change", {
            "chapter-properties-change": {
              chapter: {
                chapterId: chapter._id,
                chapterType: chapter.type,
                startOn: chapter.startOn,
                includeIn: includeIn, // updated includeIn
              }
            }
          });
        }
      }
    }
  };

}

export default new BookStore();
