


import React from "react";
import { Col, Row } from "antd";
import { getPresentableChapterType } from "../../../../utils/initials";


export const ChapterType = ({ type }: { type: IChapterStore.ChapterType }) => {
  return (
    <Row gutter={8} align="middle">
      <Col>
        <h4 className="chapter-type-toolbar">
          {getPresentableChapterType(type)}
        </h4>
      </Col>
    </Row>
  );
};

