import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MenuProps, Spin, Tooltip } from "antd";
import {
  BookIcon,
  BoxsetIcon,
  MasterPageIcon,
} from "../../../../content/icons";
import { CardFooter, CardContent, CardImage } from "./";
import { BookCardDropdown } from "../../../Books";
import useRootStore from "../../../../store/useRootStore";
import { useOnlineStatus } from "../../../../utils/hooks/isOffline";
import { LinkToBook } from "../../../Books/link-to-book";

interface BookCardViewProps {
  item: IShelfStore.ShelfItem | IShelfStore.ShlefButtonItem;
  handleButtonClick?: (buttonType: IShelfStore.ShelfButtonItemType) => void;
}

export const BookCardView: React.FC<BookCardViewProps> = (props) => {
  const { setChapterTemplateView } = useRootStore().appStore;
  const { item, handleButtonClick } = props;

  const isOnline = useOnlineStatus();

  useEffect(() => {
    setChapterTemplateView(false);
  }, []);

  const handleCreateNewBookOnClick = () => {
    if (handleButtonClick) handleButtonClick("create-book");
  };

  const renderTemplate = (
    template: IChapterStore.IChapterTemplateBase,
    actionItem: MenuProps["items"]
  ) => (
    <Link to={`/masterpage/${template._id}`}>
      <div className="book-item">
        <CardImage img="" icon={<MasterPageIcon color="#3568BA" />} />
        <CardContent title={template.title}>
          <Tooltip title={template.subtitle || ""} placement='top' color='#171D21'>
            <p className="author-title"> {template.subtitle || "-"}</p>
          </Tooltip>
          <CardFooter date={template.lastUpdateAt || "-"}>
            <BookCardDropdown actions={actionItem} />
          </CardFooter>
        </CardContent>
      </div>
    </Link>
  );

  const renderBoxset = (
    boxset: IBookStore.Book,
    actionItem: MenuProps["items"]
  ) => (
     <LinkToBook bookId={boxset._id}>
      <div className="book-item">
        <CardImage img={boxset.coverImageUrl} icon={<BoxsetIcon color="#3568BA" />} />
        <CardContent title={boxset.title}>
          <Tooltip title={boxset.author.join(",") || ""} placement='top' color='#171D21'>
            <p className="author-title">by {boxset.author.join(",") || "-"}</p>
          </Tooltip>
          <CardFooter date={boxset.lastUpdateAt || "-"}>
            <BookCardDropdown actions={actionItem} />
          </CardFooter>
        </CardContent>
      </div>
    </LinkToBook>
  );

  const renderBook = (
    book: IBookStore.Book,
    actionItem: MenuProps["items"]
  ) => (
    <LinkToBook bookId={book._id} chapterId={book.chapterIds[0]}>
      <div className='book-item'>
        <CardImage
          img={book.coverImageUrl ?? ""}
          icon={<BookIcon color='#3568BA' />}
        />
        <CardContent title={book.title}>
          <Tooltip title={book.author.join(", ") || ""} placement='top' color='#171D21'>
            <p className='author-title'>by {book.author.join(", ") || "-"}</p>
          </Tooltip>
          <CardFooter date={book.lastUpdateAt || "-"}>
            {isOnline && <BookCardDropdown actions={actionItem} />}
          </CardFooter>
        </CardContent>
      </div>
    </LinkToBook>
  );

  const renderMeta = (meta: IBookStore.InitialBook) => (
    <div className="loading-book-item">
      <div className='book-item'>
        <CardImage
          img={""}
          icon={<Spin />}
        />
        <CardContent title={meta.title}>
          <Tooltip title={meta.author.join(", ") || ""} placement='top' color='#171D21'>
            <p className='author-title'>by {meta.author.join(", ") || "-"}</p>
          </Tooltip>
          <CardFooter date={meta.lastUpdateAt ? meta.lastUpdateAt : meta.createdAt || "-"} />
        </CardContent>
      </div>
    </div>
  );

  const renderCreateBookButton = () => (
    <div className="book-item create-button-item" onClick={() => handleCreateNewBookOnClick()}>
      <span className="create-button-icon">
        <BookIcon color="#ffffff" />
      </span>
      <p className="create-button-label">Create a new book</p>
    </div>
  );

  if (item.type === "template")
    return renderTemplate(
      item.item as IChapterStore.IChapterTemplateBase,
      item.actionItem
    );

  if (item.type === "boxset")
    return renderBoxset(item.item as IBookStore.Book, item.actionItem);

  if (item.type === "create-book") return renderCreateBookButton();

  if(item.type === "meta") return renderMeta(item.item as IBookStore.InitialBook);

  return renderBook(item.item as IBookStore.Book, item.actionItem);
};
