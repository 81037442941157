import React, { useMemo } from "react";
import { Table, Row, Col } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { BookIcon, MasterPageIcon } from "../../content/icons";
import useRootStore from "../../store/useRootStore";
import { BookCardDropdown } from "../../components/Books";
import { find } from "lodash";

const collabSelectors = {
  "co_author": "Co-writer",
  "editor": "Editor",
  "beta_reader": "Beta reader"
};

export const CollaboratedBookListView = ({
  items,
}: {
  items: IShelfStore.ShelfItem[];
}) => {
  const { collaborated_books_collaborations, getCollaboratorFullName } = useRootStore().collaborationStore;
  const bookToCollab : { [id: string]: ICollabStore.BookCollaboration }  = useMemo(() => {
    const bookProps = {};
    items.forEach(item => {
      const bookId = item.item._id;
      const collab = find(collaborated_books_collaborations, { bookId  });
      if(collab && bookId){
        bookProps[bookId] = collab;
      }
    });
    return bookProps;
  }, [collaborated_books_collaborations, items]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "40%",
      render: (
        _,
        item: IShelfStore.ShelfItem) => {
          const dataItem = item.item;
          // check if item is of type book
          const isBookItem = !!(dataItem as IBookStore.Book).frontMatterIds;
          const coverImage =
            isBookItem && (dataItem as IBookStore.Book).coverImageUrl
              ? `url(${(dataItem as IBookStore.Book).coverImageUrl})`
              : "none";
          const link = isBookItem ?  `/books/${dataItem._id}/${(dataItem as IBookStore.Book).chapterIds[0]}` : `/chapter-template/${dataItem._id}`;
          const icon = isBookItem ? (
            <BookIcon color="#3568BA" />
          ) : (
            <MasterPageIcon color="#3568BA" />
          );
          return (
            <Row gutter={16} className="book-list-item" align="middle">
              <Col>
                <Link to={link}>
                  <Row
                    justify="center"
                    align="middle"
                    style={{
                      backgroundImage: coverImage,
                    }}
                    className="book-list-item-img"
                  >
                    {coverImage === "none" && icon}
                  </Row>
                </Link>
              </Col>
              <Col flex={1}>
                <h4>{dataItem.title}</h4>
              </Col>
            </Row>
          );
      },
    },
    {
      title: "Invited by",
      dataIndex: "item",
      key: "invitedBy",
      width: "15%",
      render: ({ userId }) => getCollaboratorFullName(userId)
    },
    {
      title: "Role",
      dataIndex: "item",
      key: "role",
      width: "10%",
      render: ({_id}) => collabSelectors[bookToCollab[_id].type]
    },
    {
      title: "Joined Date",
      dataIndex: "item", 
      key: "_id",
      width: "10%",
      render: ({_id}) => (bookToCollab[_id].createdAt ? moment(bookToCollab[_id].createdAt).format("MMM D, YYYY") : "-"),
    },
    {
      title: "Last modified",
      dataIndex: "item",
      key: "lastModified",
      width: "10%",
      render: ({ lastUpdateAt }) => (lastUpdateAt ? moment(lastUpdateAt).fromNow() : "-"),
    },
    {
      title: "",
      dataIndex: "actionItem",
      key: "action",
      width: "5%",
      render: (actionItem) =>
        actionItem && actionItem.length != 0 ? <BookCardDropdown actions={actionItem} /> : "-",
    },
  ];
  
  return collaborated_books_collaborations.length > 0 ? (
    <Table
      bordered
      columns={columns}
      dataSource={items}
      pagination={false}
      expandable={{ expandIcon: () => null }}
    />
  ): null;
};
