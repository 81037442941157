import React from "react";
import { Link } from "react-router-dom";
import useRootStore from "../../store/useRootStore";


export const LinkToBook: React.FC<{ bookId: string, chapterId?: string }> = ({ bookId, chapterId, children }) => {
  const { resetState } = useRootStore().bookStore;
  return (
    <Link onClick={resetState} to={chapterId ? `/books/${bookId}/${chapterId}` : `/books/${bookId}`}>
      {children}
    </Link>
  );
};