
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Layout, Col, Form, Row, Radio, Tooltip, Spin } from "antd";
import { debounce } from "lodash";

import useRootStore from "../../store/useRootStore";

import FileUpload from "../../components/Shared/Forms/FileUpload";
import ImageGallery from "../../components/ImageGallery";
import { LoadingOutlined } from "@ant-design/icons";

// partials
import { ButtonSize } from "../../components/Shared/Buttons/Button";
import { AtticusRadioButton } from "../../components/Shared/Form/FormControls";
import { UploadIcon, InfoIcon } from "../../content/icons";
import { AtticusDivider } from "../../components/Shared/Divider";
import { DeleteImageButton } from "../../components/Shared/Buttons";
import { ChapterTitle, ChapterType } from "../../components/Plate/partials";
import { ChapterConfiguration } from "../../components/Plate/partials/ChapterConfiguration";

export const FullPageImage = observer(() => {
  const { chapterMeta, syncActiveChapterMeta } = useRootStore().chapterStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;

  const [image, setImage] = useState(chapterMeta.fullpageImage?.imageUrl ? chapterMeta.fullpageImage.imageUrl : "");
  const [uploading, setUploading] = useState(false);
  const [printExtent, setPrintExtent] = useState(
    chapterMeta.fullpageImage?.printExtent
      ? chapterMeta.fullpageImage.printExtent
      : "margins"
  );
  const [verticalAlignment, setVerticalAlignment] = useState(
    chapterMeta.fullpageImage?.verticalAlignment
      ? chapterMeta.fullpageImage.verticalAlignment
      : "center"
  );
  const [imageGalleryUrl, setImageGalleryUrl] = useState("");
  const [imageSize, setImageSize] = useState("");
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    setImage(chapterMeta.fullpageImage?.imageUrl ? chapterMeta.fullpageImage.imageUrl : "");
    setPrintExtent(chapterMeta.fullpageImage?.printExtent ? chapterMeta.fullpageImage.printExtent : "margins");
    setVerticalAlignment(chapterMeta.fullpageImage?.verticalAlignment ? chapterMeta.fullpageImage.verticalAlignment : "center");
  }, [chapterMeta._id, chapterMeta.fullpageImage]);

  const getIGURL = (link) => {
    setImage(link);
    setImageGalleryUrl(link);
  };

  const debouncedSyncChapterActive = debounce(syncActiveChapterMeta, 1000);
  const debouncedRefreshCache = debounce(refreshCache, 1000);

  const debouncedUpdates = () => {
    debouncedSyncChapterActive({
      fullpageImage: {
        imageUrl: image,
        printExtent: printExtent,
        verticalAlignment: verticalAlignment,
      },
    });
    refreshCache(chapterMeta.bookId, "full-page-image-chapter-change", { "full-page-image-chapter-change": { chapterId: chapterMeta._id } });
  };

  const getImageSize = async () => {
    const response = await fetch(image);
    const contentLength = response.headers.get("Content-Length");

    if (contentLength) {
      const sizeInBytes = parseInt(contentLength, 10);
      const sizeInMb = sizeInBytes / (1024 * 1024);
      setImageSize(sizeInMb.toFixed(2) + "mb");
    }
  };

  const extractImageName = (url) => {
    const urlSegments = url.split("/");
    const lastSegment = urlSegments[urlSegments.length - 1];
    if (/\.(jpg|jpeg|png)$/.test(lastSegment)) {
      setImageName(lastSegment);
    }
  };

  const handleTitleChange = (title: string) => {
    debouncedSyncChapterActive({ title });
    debouncedRefreshCache(chapterMeta.bookId, "chapter-title-change", {
      "chapter-title-change": { chapterId: chapterMeta._id },
    });
  };

  useEffect(() => {
    getImageSize();
    extractImageName(image);
  }, [image]);

  useEffect(() => {
    debouncedUpdates();
  }, [imageGalleryUrl]);

  return (
    <Layout.Content className="fullpage-img-layout atticus-editor-container">
      <>
        <div className={chapterMeta.title === "Title Page" ? "title-page-area scroller" : "fullpage-img-area scroller"}>
          <Form layout="vertical">
            <Form
              layout="vertical"
              className="title-page-form"
            >
              <Row>
                <Col span={12} className="title-page-container">
                  <div className="image-toolbar-body-wrapper">
                    <Row className="att-editor-bar full-page-image-titlebar">
                      <ChapterType type="image" />
                      <Row className="title-bar-wrapper" align="middle">
                        <Col flex={1}>
                          <ChapterTitle
                            initialValue={chapterMeta.title || ""}
                            onEnter={() => ({})}
                            autofocus={false}
                            onTitleChange={handleTitleChange}
                          />
                        </Col>
                        <div className="title-bar-icon-holder">
                          <Col>
                              <ChapterConfiguration variant="image" />
                          </Col>
                        </div>
                      </Row>
                    </Row>
                    <div className="inner-xs"></div>
                    <ImageGallery
                      handleImageUrl={getIGURL}
                      renderButtonProps={{
                        type: "at-primary",
                        backgroundColor: "green",
                        size: ButtonSize.MEDIUM,
                        block: true
                      }}
                    />
                    <AtticusDivider className="title-page-divider">
                      <p className="image-toolbar-divider-text">Or</p>
                    </AtticusDivider>
                    <Row className="title-page-text-holder">
                      <h4 className="image-toolbar-upload-header">
                        Upload a new image
                      </h4>
                    </Row>
                    {image ? (
                      <>
                        <div
                          className="image-toolbar-image-previewer title-page-image-previewer"
                          style={{ backgroundImage: `url(${image})` }}
                        >
                          <div className="image-toolbar-image-previewer-container">
                            <DeleteImageButton onClick={() => {
                              setImage("");
                              debouncedSyncChapterActive({
                                fullpageImage: {
                                  imageUrl: "",
                                  printExtent: printExtent,
                                  verticalAlignment: verticalAlignment,
                                },
                              });
                              refreshCache(chapterMeta.bookId, "full-page-image-chapter-change", { "full-page-image-chapter-change": { chapterId: chapterMeta._id } });
                            }} />
                          </div>
                        </div>
                        <div className="image-toolbar-upload-footer">
                          <p className="image-toolbar-upload-footer-text">
                            {imageName}

                          </p>
                          <p className="image-toolbar-upload-footer-text">
                            {imageSize}
                          </p>
                        </div>
                      </>
                    ) : (
                      <div>
                        <Row className="title-page-file-upload-container">
                          <Col span={24} className="image-upload-wrapper">
                            <Form.Item
                              name="coverImg"
                              valuePropName="fileList"
                              noStyle
                            >
                              <FileUpload
                                folder="cover-images"
                                onFileUploadEnd={() => setUploading(false)}
                                onFileUploadStart={() => setUploading(true)}
                                fileType="image/png, image/jpg, image/jpeg"
                                onFileUpload={(url) => {
                                  if (!url) return;
                                  setImage(url || "");
                                  setIsChapterLocalUpdate(true);
                                  setChangeEvent("full-page-image-chapter-change");
                                  debouncedSyncChapterActive({
                                    fullpageImage: {
                                      imageUrl: url,
                                      printExtent: printExtent,
                                      verticalAlignment: verticalAlignment,
                                    },
                                  });
                                  refreshCache(chapterMeta.bookId, "full-page-image-chapter-change", { "full-page-image-chapter-change": { chapterId: chapterMeta._id } });
                                }}
                                styleOverrides={{
                                  backgroundColor: "#E7EDF8",
                                  borderRadius: 4,
                                  borderWidth: 2,
                                  borderStyle: "dashed",
                                  borderColor: "#ADC1E3",
                                }}
                              >
                                <div className="image-toolbar-dnd-container">
                                  {uploading ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined className="edit-book-spinner" />
                                      }
                                    />
                                  ) : (
                                    <>
                                      <span>
                                        <UploadIcon color="#3363B9" />
                                      </span>
                                      <p>
                                        <span className="image-toolbar-dnd-text-highlight">
                                          Click to upload image
                                        </span>{" "}
                                        or drag and drop
                                      </p>
                                    </>
                                  )}
                                </div>
                              </FileUpload>
                            </Form.Item>
                            {!uploading ? <div className="image-toolbar-upload-footer">
                              <p className="image-toolbar-upload-footer-text">
                                Supported formats: PNG, JPG
                              </p>
                              <p className="image-toolbar-upload-footer-text">
                                Maximum size: 10mb
                              </p>
                            </div> : <></>}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                  <Row className="title-page-form-container">
                    <Col span={24}>
                      <Row>
                        Print extent
                        <Tooltip
                          placement="bottom"
                          title={<span>Full bleed image should match the page trim size plus the bleed allowance.</span>}
                        >
                          <div className="info-icon-wrapper">
                            <InfoIcon />
                          </div>
                        </Tooltip>
                      </Row>
                    </Col>
                    <Form.Item className="at-form-item">
                      <Radio.Group
                        value={printExtent}
                        onChange={(value) => {
                          if (value.target.value) {
                            setPrintExtent(value.target.value);
                            setIsChapterLocalUpdate(true);
                            setChangeEvent("full-page-image-chapter-change");
                            debouncedSyncChapterActive({
                              fullpageImage: {
                                imageUrl: image,
                                printExtent: value.target
                                  .value as any,
                                verticalAlignment: verticalAlignment,
                              },
                            });
                            refreshCache(chapterMeta.bookId, "full-page-image-chapter-change", { "full-page-image-chapter-change": { chapterId: chapterMeta._id } });
                          }
                        }}
                      >
                        <AtticusRadioButton
                          type="secondary"
                          value="margins"
                        >
                          Margins
                        </AtticusRadioButton>
                        <AtticusRadioButton
                          type="secondary"
                          value="full-bleed"
                        >
                          Full Bleed
                        </AtticusRadioButton>
                      </Radio.Group>
                    </Form.Item>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Row>
                        Vertical alignment
                        <Tooltip
                          placement="bottom"
                          title={<span>Vertical alignment will only be applied to the print version (PDF).</span>}
                        >
                          <div className="info-icon-wrapper">
                            <InfoIcon />
                          </div>
                        </Tooltip>
                      </Row>
                    </Col>
                    <Col>
                      <Form.Item className="at-form-item">
                        <Radio.Group
                          disabled={
                            printExtent === "full-bleed" ? true : false
                          }
                          value={verticalAlignment}
                          onChange={(value) => {
                            setVerticalAlignment(value.target.value);
                            setIsChapterLocalUpdate(true);
                            setChangeEvent("full-page-image-chapter-change");
                            debouncedSyncChapterActive({
                              fullpageImage: {
                                imageUrl: image,
                                printExtent: printExtent,
                                verticalAlignment: value.target
                                  .value as any,
                              },
                            });
                            refreshCache(chapterMeta.bookId, "full-page-image-chapter-change", { "full-page-image-chapter-change": { chapterId: chapterMeta._id } });
                          }}
                        >
                          <AtticusRadioButton
                            type="secondary"
                            value="top"
                          >
                            Top
                          </AtticusRadioButton>
                          <AtticusRadioButton
                            type="secondary"
                            value="center"
                          >
                            Center
                          </AtticusRadioButton>
                          <AtticusRadioButton
                            type="secondary"
                            value="bottom"
                          >
                            Bottom
                          </AtticusRadioButton>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Form>
        </div>
      </>
    </Layout.Content>
  );
});
