import { makeAutoObservable, observable } from "mobx";
import { Emitter } from "mitt";

import { makeid } from "../utils/helper";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../utils/chapter";

export class AppStore {
  topContainerPosition = 60;
  initialChaptersLoaded = false;
  isDragAndDropEvenet = false;
  sprintInit = false;
  appIdle = false;
  chapterTemplateView = false;
  editor_setting : IAppStore.EditorSettings = {
    view: "goals",
    show: false,
    isPreviewerOpen: false
  };
  sidebarPreviewerOnlyMode = false;
  previewerConfigs : IAppStore.PreviewerConfigs = {
    deviceName: "ipad",
    l1ClassName: "ipad",
    l2ClassName: "inside",
    width: 440,
    deviceUrl: "https://atticus-content.s3.amazonaws.com/devices/iPad+Pro.png"
  };
  chapTemplate: IChapterStore.IChapterTemplateBase = {
    _id: "",
    type: "uncategorized",
    title: "",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    image: "",
    children: []
  };
  editor: IAppStore.EditorConfigs = {
    indentation: "spaced",
    justified: false,
    font: "",
    fontsize: 16,
    linespacing: 1.5
  };
  // socialProfile:  ISocialProfileStore.IProfiles[] = [];
  selectedSocialProfile = {
    profileName: "",
    smAccounts: [{
      id: "",
    name: "",
    username: "",
    icon: ""
    }],
    userId: ""
  };
  sessionId = makeid(12)
  displayState = "default";
  /**
   * To identify the global local pdf renderer call
   */
  pdfExporterOptions: IAppStore.PDFExporterOptions = {} as IAppStore.PDFExporterOptions
  /**
   * App level event emitter for communication between independent components
   */
  public appEventEmitter:  Emitter<IAppEvents.AppEvents> | null = null;

  constructor() {
    makeAutoObservable(this, {
      editor: observable.deep,
      pdfExporterOptions: observable.deep
    });
  }

  setContainerTopPosition = (top: number): void => {
    this.topContainerPosition = top;
  }

  setIsDragAndDropEvenet = ( isDragAndDropEvenet : boolean): void => {
    this.isDragAndDropEvenet = isDragAndDropEvenet;
  }

  getIsDragAndDropEvenet = (): boolean => {
    return this.isDragAndDropEvenet;
  }

  setInitialChaptersLoaded = (initialChaptersLoaded: boolean): void => {
    this.initialChaptersLoaded = initialChaptersLoaded;
  }
  
  setSettingView = (config: IAppStore.EditorSettings): void => {
    this.editor_setting = config;
  }

  setSidebarPreviewerOnlyMode = (sidebarPreviewerOnlyMode: boolean): void => {
    this.sidebarPreviewerOnlyMode = sidebarPreviewerOnlyMode;
  }

  setPreviewerConfigs = (previewerConfigs: IAppStore.PreviewerConfigs): void => {
    this.previewerConfigs = previewerConfigs;
  }

  patchEditor = (conf: Partial<IAppStore.EditorConfigs>) => {
    this.editor = { ...this.editor, ...conf };
  }

  setIdleState = (idle: boolean) => {
    this.appIdle = idle;
  }

  setSprintInit = (view: boolean): void => {
    this.sprintInit = view;
  }

  setChapterTemplateView = (view: boolean): void => {
    this.chapterTemplateView = view;
  }

  setMainChapterTemplate = (temp: IChapterStore.IChapterTemplateBase): void => {
    this.chapTemplate = temp;
  }

  setSelectedProfile = async(profile: any): Promise<void> => {
    this.selectedSocialProfile = profile;
  }

  setDisplayState = (dState: string) => {
    this.displayState = dState;
  }

  setPDFExproterOptions = (bookId: string, bookName: string): void => {
    if (this.pdfExporterOptions.renderBook) {
      this.pdfExporterOptions.renderBook(bookId, bookName);
    }
  }

  setEventEmitter = (emitter: Emitter<IAppEvents.AppEvents>): void => {
    this.appEventEmitter = emitter;
  }

  getEventEmitter = (): Emitter<IAppEvents.AppEvents> | null => {
    return this.appEventEmitter;
  }

  getEditorSetting = (): IAppStore.EditorSettings =>{
    return this.editor_setting;
  }

}
export default new AppStore();