import React from "react";
import { makeAutoObservable, toJS } from "mobx";
import { AtticusClient } from "../api/atticus.api";
import { Dialog } from "../components/Shared/Modal";
import { message } from "antd";
import { BookCollaborator, Quota } from "../types/collaboration";
import {
  SaveCollaboratedThemesToIDB,
  SaveCollaboratedBookToIDB,
  SaveCollaboratedCollaborationsToIDB,
  SaveCollaboratedUserMetasToIDB,
  SaveCollaborationsToIDB,
  SaveCollaborationUserMetasToIDB,
  SaveOneCollaboratedBookToIDB
} from "../db/helpers/collaboration";
import { DeleteBooksFromDB, GetBooksFromDB } from "../utils/offline.book.helpers";
import { find } from "lodash";
import { syncBookBaseData } from "../utils/sync";
import { getQuotaKey } from "../utils/book-collaboration";

export class CollaborationStore {
  collaborated_books: IBookStore.Book[] = [];
  collaborated_books_meta: IBookStore.InitialBook[] = [];
  collaborated_books_theme: IThemeStore.Theme[] = [];
  collaborated_books_collaborations: ICollabStore.BookCollaboration[] = [];
  collaborated_user_metas: ICollabStore.AuthorMetaMap = {};
  collaborators: ICollabStore.AuthorMetaMap = {};
  collaborations: ICollabStore.BookCollaboration[] = [];
  mounted = false;
  showPopover = false;
  bookCollaborators: BookCollaborator[] = [];
  allocatedQuota: Quota = {
    co_authors: 0,
    editors: 0,
    beta_readers: 0,
  };
  remainingQuota: Quota = {
    co_authors: 0,
    editors: 0,
    beta_readers: 0,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setTogglePopover = (show) => {
    this.showPopover = show;
  }

  setCollaberatedBooks = (books: IBookStore.Book[]): void => {
    this.collaborated_books = books;
  }

  setMetaCollaberatedBooks = (meta_books: IBookStore.InitialBook[]): void => {
    this.collaborated_books_meta = meta_books;
  }

  addCollaberatedBook = (books: IBookStore.Book[]): void => {
    this.collaborated_books = [...this.collaborated_books, ...books];
  }

  setOneCollaborator = (author: ICollabStore.AuthorMeta, id: string) => {
    if (author.email) {
      const collaborators = toJS(this.collaborators);
      collaborators[id] = author;

      this.setCollaborators(collaborators);
    }
  }

  setCollaborators = (collaborators: ICollabStore.AuthorMetaMap): void => {
    this.collaborators = collaborators;
  }

  setCollaboratedBooksCollaborations = (collaborations: ICollabStore.BookCollaboration[]): void => {
    this.collaborated_books_collaborations = collaborations;
  }

  addCollaboratedBooksCollaboration = (collaborations: ICollabStore.BookCollaboration[]): void => {
    this.collaborated_books_collaborations = [...this.collaborated_books_collaborations, ...collaborations];
  }

  setOneCollaboratedBooksCollaboration = (owner: ICollabStore.BookCollaboration) => {
    if (owner._id) {
      const collaborations = toJS(this.collaborated_books_collaborations);
      collaborations.push(owner);

      this.setCollaborations(collaborations);
    }
  }

  setCollaboratedBooksTheme = (themes: IThemeStore.Theme[]) => {
    this.collaborated_books_theme = themes;
  }

  setOneCollaboratedBooksTheme = (theme: IThemeStore.Theme) => {
    if (theme._id) {
      const themes = toJS(this.collaborated_books_theme);
      themes.push(theme);

      this.setCollaboratedBooksTheme(themes);
    }
  }

  mount = (): void => {
    this.mounted = true;
  }

  setCollaborations = (collaborations: ICollabStore.BookCollaboration[]): void => {
    this.collaborations = collaborations;
  }

  setOneCollaboration = (collaboration: ICollabStore.BookCollaboration): void => {
    if (collaboration._id) {
      const collabs = toJS(this.collaborations);
      collabs.push(collaboration);

      this.setCollaborations(collabs);
    }
  }

  setBookCollaborators = (
    collaborators: ICollabStore.BookCollaborator[]
  ): void => {
    this.bookCollaborators = collaborators;
  };

  setCollaboratedUserMetas = (metas: ICollabStore.AuthorMetaMap) => {
    this.collaborated_user_metas = metas;
  }

  setAllocatedQuota = (quota: Quota): void => {
    this.allocatedQuota = quota;
  };

  setRemainingQuota = (quota: Quota): void => {
    this.remainingQuota = quota;
  };

  saveCollaborated = async (collaborations: ICollabStore.BookCollaboration[]) => {
    this.setCollaboratedBooksCollaborations(collaborations);
    await SaveCollaboratedCollaborationsToIDB(collaborations);
  }

  saveCollaboratedThemes = async (themes: IThemeStore.ThemeResponse[]) => {
    this.setCollaboratedBooksTheme(themes);
    await SaveCollaboratedThemesToIDB(themes);
  }

  saveCollaboratedBooks = async (books: IBookStore.Book[]) => {
    this.setCollaberatedBooks(books);
    await SaveCollaboratedBookToIDB(books);
  }

  appendCollaboratedBooks = async (book: IBookStore.Book) => {
    this.setCollaberatedBooks([...this.collaborated_books, book]);
    await SaveOneCollaboratedBookToIDB(book);
  }

  saveCollaboratedUserMetas = async (metas: ICollabStore.AuthorMetaMap) => {
    this.setCollaboratedUserMetas(metas);
    await SaveCollaboratedUserMetasToIDB(metas);
  }

  saveCollaborations = async (collaborations: ICollabStore.BookCollaboration[]) => {
    this.setCollaborations(collaborations);
    await SaveCollaborationsToIDB(collaborations);
  }

  saveCollaborators = async (metas: ICollabStore.AuthorMetaMap) => {
    this.setCollaborators(metas);
    await SaveCollaborationUserMetasToIDB(metas);
  }

  renderInviteSentMessage = (email: string) => message.success("Invitation sent to " + email)

  // Load collaborations and collaborator details where you are the owner
  loadCollaborations = async (): Promise<void> => {
    const {
      collaborators,
      collaborations
    } = await AtticusClient.GetCollaborations();

    await this.saveCollaborations(collaborations);
    await this.saveCollaborators(collaborators);
    this.mount();
  }

  // Load collaborations, books and themes where you are the collaborator
  loadCollaborated = async (): Promise<void> => {
    const {
      themes = [],
      books = [],
      collaborations = [],
      authors = {}
    } = await AtticusClient.GetCollaborated();

    await this.saveCollaboratedBooks(books);
    await this.saveCollaboratedThemes(themes);
    await this.saveCollaborated(collaborations);
    await this.saveCollaboratedUserMetas(authors);
    this.mount();
  }

  doFullCollaboratedBookLoad = async (ids: string[]) => {
    const fullBookLoadPromises: Promise<void>[] = [];
    for (let i = 0; i < ids.length; i++) {
      fullBookLoadPromises.push(
        syncBookBaseData(ids[i]).then((book) => {
          this.appendCollaboratedBooks(book);
        })
      );
    }
    await Promise.allSettled(fullBookLoadPromises);
  };


  loadUserCollaborations = async () => {
    const { collaborations, authors } = await AtticusClient.GetUserCollaboration();

    await this.saveCollaborated(collaborations);
    await this.saveCollaboratedUserMetas(authors);
  }

  loadCollaboratedThemes = async () => {
    const { themes } = await AtticusClient.GetCollaboratedThemes();
    await this.saveCollaboratedThemes(themes);
  }

  loadCollaboratedBooks = async (): Promise<void> => {
    const [, books] = await GetBooksFromDB();
    const collabBooks = books.filter((book) => book.collaborated);
    this.setCollaberatedBooks(collabBooks);
  };

  getCollaboratedBooks = (): IBookStore.Book[]=> {
    return this.collaborated_books;
  }

  getMetaCollaberatedBooks = (): IBookStore.InitialBook[] => {
    return this.collaborated_books_meta;
  }

  getCollaborationForBook = (bookId: string): ICollabStore.AuthorMetaWithCollaborationMeta[] => {
    return this.collaborations
      .filter(c => c.bookId === bookId)
      .map((c) => ({
        ...this.collaborators[c.userId],
        status: c.status,
        email: c.email,
        collaboration_id: c._id,
        userId: c.userId,
        type: c.type
      }));
  };

  addCollaboratorToBook = async (params: ICollabStore.AddCollaboratorPayload) => {
    const { collaboration, active_user, success } = await this.callAddCollaboratorToBook(params);
    if (success) {

      if (collaboration && active_user) {
        if (active_user.email && collaboration.userId) {
          this.setOneCollaborator(active_user, collaboration.userId);
        }
        this.setOneCollaboration(collaboration);
        this.renderInviteSentMessage(collaboration.email);
      }

      if (!active_user) {
        Dialog({
          width: 600,
          title: "Email not associated with an Atticus account",
          content: "The provided email address is not linked to an Atticus account. Would you like to send an invitation to join Atticus, or close this dialog box and try another email?",
          leftBtn: {
            type: "at-primary",
            backgroundColor: "white",
            children: "Close",
          },
          rightBtn: {
            type: "at-primary",
            backgroundColor: "green",
            onMouseDown: () => this.handleAddPendingCollaborator(params),
            children: "Invite Anyway",
          },
        });
      }
    }
    return collaboration;
  }

  handleAddPendingCollaborator = async (params: ICollabStore.AddCollaboratorPayload) => {
    try {
      const { collaboration, success } = await this.callAddPendingCollaboratorToBook(params);

      if (success && collaboration) {
        this.setOneCollaboration(collaboration);
        this.renderInviteSentMessage(collaboration.email);
        this.calculateRemainingQuota();
      } else {
        throw Error();
      }
    } catch (e: any) {
      const { response } = e;
      message.error(response.data.message || "Error in Adding a collaborator");
    }
  }

  removeCollaboration = async (collaboration_id: string) => {
    const _removable = find(this.collaborations, { _id: collaboration_id });

    if (_removable) {
      const removed_array = this.collaborations.filter(theme => theme._id !== _removable._id);
      await this.saveCollaborations(removed_array);
    }
  }

  removeCollaboratedBooksCollaborations = (bookId: string) => {
    const removed_array = this.collaborated_books_collaborations.filter(collaborations => collaborations.bookId !== bookId);
    this.setCollaboratedBooksCollaborations(removed_array);
  }

  removeAccessByCollabUser = async (bookId: string) => {
    try {
      const resp = await AtticusClient.RemoveAccessFromCollabBook(bookId);
      if (resp) {
        await DeleteBooksFromDB([bookId]);

        const filtered = this.collaborated_books.filter((book) => book._id !== bookId);
        this.saveCollaboratedBooks(filtered);
      }
    } catch (e) {
      message.error({
        message: "Could not remove yourself from the book!",
      });
    }
  };


  handleRemoveAccess = async (book: IBookStore.Book) => {
    return Dialog({
      open: false,
      title: "Remove access",
      content: `Are you sure you want to remove access to '${book.title}'?`,
      leftBtn: {
        children: "No",
      },
      rightBtn: {
        danger: true,
        onClick: async () => {
          const res = await this.removeAccessByCollabUser(book._id);
        },
        children: "Yes",
      },
    });
  };

  removeCollaboratorToBook = async (id: string) => {
    const resp = await this.callRemoveCollaboratorFromBook(id);
    this.setCollaborations(this.collaborations.filter(c => c._id !== id));

    return resp;
  }

  getCollaboratorFullName = (id: string) => {
    const user = this.collaborated_user_metas[id];
    if (id && user) {
      return `${user.firstName} ${user.lastName}`;
    }
    return "";
  }

  clearMetaCollaboratedBooks = () => {
    this.setMetaCollaberatedBooks([]);
  }

  callAddCollaboratorToBook = (params: ICollabStore.AddCollaboratorPayload) => AtticusClient.AddCollaborator(params);

  callAddPendingCollaboratorToBook = (params: ICollabStore.AddCollaboratorPayload) => AtticusClient.AddPendingCollaborator(params);

  callRemoveCollaboratorFromBook = (id: string) => AtticusClient.RemoveCollaboration(id);

  getAndSetCollaboratorsForBook = async (bookId: string): Promise<void> => {
    const { bookCollaborators } = await AtticusClient.GetBookCollaborators(
      bookId
    );
    this.setBookCollaborators(bookCollaborators);
  };

  calculateRemainingQuota = (): void => {
    const quotaUsed: Quota = {
      co_authors: 0,
      editors: 0,
      beta_readers: 0,
    };

    this.collaborations
      .filter(
        (collaboration) =>
          collaboration.status === "accepted" ||
          collaboration.status === "pending"
      )
      .forEach((collaboration) => {
        const key = getQuotaKey(collaboration.type);
        if (key) quotaUsed[key]++;
      });

    const remaining: Quota = Object.fromEntries(
      Object.entries(this.allocatedQuota).map(([key, value]) => [
        key,
        value - quotaUsed[key as keyof Quota],
      ])
    ) as Quota;

    this.setRemainingQuota(remaining);
  };
}

export default new CollaborationStore();
