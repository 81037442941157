import React, { useState, useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { debounce } from "lodash";
import { Row, Col, Input } from "antd";
import { PlateEditor } from "@udecode/plate";

import useRootStore from "../../../../store/useRootStore";
import { SceneUtils } from "../../../../utils/scene/sceneServices";
import { can } from "../../../casl/helpers";

interface TitlebarProps {
  onEnter: any;
  autofocus: boolean;
  editor?: PlateEditor | null;
}

export const SceneBar = observer((props: TitlebarProps) => {
  const {
    onEnter,
    autofocus,
    editor,
  } = props;
  const { currentScene, chapterMeta: currentChapter } = useRootStore().chapterStore;
  const { setChapterScenes, sceneCache } = useRootStore().sideMenuStore;

  const [sceneTitle, setSceneTitle] = useState(currentScene?.scene.sceneTitle || "");

  const debounceSetSceneTitleInEditorAndSidebar = useCallback(
    debounce((title: string) => {
      /** update the plate nodes with the new title */
      if (!editor) return;
      if (!currentScene) return;
      SceneUtils.updateEditorSceneTitle(editor, currentScene, title);
      /**
       * update the sidebar;
       * this wont automatically refresh since scene normalizer only runs
       * on ornamental break changes
       */
      const currentChapterScenes = sceneCache.get(currentChapter._id);
      if (currentChapterScenes?.length) {
        const updatedScenes = currentChapterScenes.map((scene) => {
          if (scene.sceneIndex === currentScene?.scene.sceneIndex) {
            return {
              ...scene,
              sceneTitle: title,
            };
          }
          return scene;
        });
        setChapterScenes(currentChapter._id, updatedScenes);
      }
    }, 500),
    [editor, currentScene]
  );

  const sceneTitleOnChange = (sceneTitle: string) => {
    setSceneTitle(sceneTitle);
    debounceSetSceneTitleInEditorAndSidebar(sceneTitle);
  };

  useEffect(() => {
    setSceneTitle(currentScene?.scene.sceneTitle || "");
  }, [currentScene?.scene.id]);

  return (
    <div>
      <Input
        className="att-editor-title"
        type="text"
        value={sceneTitle}
        disabled={!can("view", "chapter-title-edit")}
        placeholder={"Scene Title"}
        autoFocus={autofocus}
        bordered={false}
        onChange={(event) => sceneTitleOnChange(event.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onEnter();
          }
        }}
      />
    </div>
  );
});
