


import React, { useState } from "react";
import { Input } from "antd";
import { can } from "../../../casl/helpers";
import { PlateEditor } from "@udecode/plate";
import useRootStore from "../../../../store/useRootStore";

interface TitlebarProps {
  initialValue: string,
  onEnter: any;
  autofocus: boolean;
  onSubtitleChange: (title: string) => void;
}

export const ChapterSubtitle = ({ initialValue, autofocus, onEnter, onSubtitleChange }: TitlebarProps) => {
  const [subtitle, setSubtitle] = useState(initialValue || "");
  const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;
  
  const handler = (val: string) => {
    setSubtitle(val);
    setIsChapterLocalUpdate(true);
    setChangeEvent("chapter-subtitle-change");
    onSubtitleChange(val);
  };

  return (
    <div>
      <Input
        className="att-editor-subtitle"
        type="text"
        color="secondary"
        value={subtitle}
        disabled={!can("view", "chapter-subtitle-edit")}
        placeholder={"Subtitle"}
        autoFocus={autofocus}
        bordered={false}
        onChange={(event) => handler(event.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onEnter();
          }
        }}
      />
    </div>
  );
};

