import React from "react";
import { Table, Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

import { BookCardDropdown } from "./book-card-dropdown";
import { BookIcon, MasterPageIcon } from "../../content/icons";
import { ShelfItemTypeObj } from "../../types/shelf";
import { LinkToBook } from "./link-to-book";

type ListItemType = ShelfItemTypeObj & { type: IShelfStore.ShelfItemType, actionItem: any }

export const BookListView = ({
  items,
  handleButtonClick,
}: {
  items: (IShelfStore.ShelfItem | IShelfStore.ShlefButtonItem)[];
  handleButtonClick?: (buttonType: IShelfStore.ShelfButtonItemType) => void;
}): JSX.Element => {
  const handleCreateNewBookOnClick = () => {
    if (handleButtonClick) handleButtonClick("create-book");
  };

  const parsedBooks = items.map((item) => {
    if (item.type === "create-book") {
      return item;
    }
    return { ...item.item, type: item.type, actionItem: item.actionItem } as ListItemType;
  });

  const renderMetaCard = (item: IBookStore.InitialBook) => (
    <Row gutter={16} className="book-list-item loading-book-list-item" align="middle">
      <Col>
        <Row
          justify="center"
          align="middle"
          className="book-list-item-img"
        >
          <Spin />
        </Row>
      </Col>
      <Col flex={1}>
        <h4>{item.title}</h4>
      </Col>
    </Row>
  );

  const renderBookCard = (item: IBookStore.Book) => (
    <Row gutter={16} className="book-list-item" align="middle">
      <Col>
        <LinkToBook bookId={item._id}>
          <Row
            justify="center"
            align="middle"
            style={{
              backgroundImage: item.coverImageUrl ? `url(${item.coverImageUrl})` : "none",
            }}
            className="book-list-item-img"
          >
            {!item.coverImageUrl && <BookIcon color="#3568BA" />}
          </Row>
        </LinkToBook>
      </Col>
      <Col flex={1}>
        <h4>{item.title}</h4>
      </Col>
    </Row>
  );

  const renderTemplateCard = (item: IChapterStore.IChapterTemplateBase) => (
    <Row gutter={16} className="book-list-item" align="middle">
      <Col>
        <Link to={`/masterpage/${item._id}`}>
          <Row
            justify="center"
            align="middle"
            className="book-list-item-img"
          >
            <MasterPageIcon color="#3568BA" />
          </Row>
        </Link>
      </Col>
      <Col flex={1}>
        <h4>{item.title}</h4>
      </Col>
    </Row>
  );

  const renderTitleCard = (item: ListItemType) => {
    if (item.type === "meta") {
      return renderMetaCard(item as IBookStore.InitialBook);
    }
    if (item.type === "template") {
      return renderTemplateCard(item as unknown as IChapterStore.IChapterTemplateBase);
    }
    return renderBookCard(item as IBookStore.Book);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "40%",
      render: (
        _,
        item: ListItemType | IShelfStore.ShlefButtonItem
      ) => {
        // Render button items
        if ((item as IShelfStore.ShlefButtonItem).type === "create-book") {
          return (
            <Row gutter={16} className="book-list-item" align="middle">
              <Col>
                <Row
                  justify="center"
                  align="middle"
                  className="book-list-item-btn"
                  onClick={() => handleCreateNewBookOnClick()}
                >
                  <BookIcon color="#FFFFFF" />
                </Row>
              </Col>
              <Col flex={1}>
                <h4>Create a new book</h4>
              </Col>
            </Row>
          );
        } else {
          return renderTitleCard(item as ListItemType);
        }
      },
    },
    {
      title: "Details",
      dataIndex: "author",
      key: "details",
      width: "15%",
      render: (author: string[]) => (author?.length > 0 ? author.join(", ") : "-"),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      width: "10%",
      render: (project) => (project ? project : "-"),
    },
    {
      title: "Version",
      dataIndex: "versionTags",
      key: "version",
      width: "10%",
      render: (version) => (version?.length ? version.join(", ") : "-"),
    },
    {
      title: "Last modified",
      dataIndex: "lastUpdateAt",
      key: "lastModified",
      width: "10%",
      render: (dt) => (dt ? moment(dt).fromNow() : "-"),
    },
    {
      title: "",
      dataIndex: "actionItem",
      key: "action",
      width: "5%",
      render: (actionItem) =>
        actionItem && actionItem.length != 0 ? <BookCardDropdown actions={actionItem} /> : "-",
    },
  ];

  return (
    <Table
      bordered
      columns={columns}
      dataSource={parsedBooks}
      pagination={false}
      expandable={{ expandIcon: () => null }}
    />
  );
};
