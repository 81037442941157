import {
  getObjectArrayDifference,
  getObjectArrayIntersection,
  withTimeout,
} from "../helper";
import {
  localBookToRemoteBook,
  saveBookToLocalDB,
  saveBookToRemoteDB,
  updateBookInRemoteDB,
  patchBookInLocalDB,
  getBookFromIDB,
  getBookFromRemoteDB,
} from "./helper";
import { syncBookChapterBodies } from "./chapterBodySync";
import { AtticusClient } from "../../api/atticus.api";
import { GetBooksFromDB } from "../offline.book.helpers";


/** sync specific book */
export const syncBook = async (bookId: string): Promise<void> => {
  const bookInLocalDB = await getBookFromIDB(bookId);
  const bookInRemoteDB = await getBookFromRemoteDB(bookId);
  
  /** If the book exists in the remote DB but not in the local DB, save it to the local DB */
  if (bookInRemoteDB && !bookInLocalDB) {
    await syncBookToLocal(bookId, bookInRemoteDB);
    return;
  }
  /** If the book exists in the local DB but not in the remote DB, save it to the remote DB */
  if (bookInLocalDB && !bookInRemoteDB) {
    await syncBookToRemote(bookId, bookInLocalDB);
    return;
  }

  /** If the book exists in both local and remote DB, sync changes */
  if (bookInLocalDB && bookInRemoteDB) {
    await syncRemoteAndLocalBookChanges(bookInRemoteDB, bookInLocalDB);
  }
};

export const syncBookToRemote = async (bookId: string, book?: IBookStore.ExpandedBook) => {
  const localBook = book || await getBookFromIDB(bookId);

  const bookToSaveInRemoteDB = localBookToRemoteBook(localBook);
  const timeStamp = await saveBookToRemoteDB(bookToSaveInRemoteDB);
  await patchBookInLocalDB({
    _id: localBook._id,
    lastSuccessfulSync: timeStamp,
    lastUpdateAt: timeStamp,
    allChangesSynced: true,
  });
};

export const syncBookToLocal = async (bookId: string, book?: IBookStore.RemoteBook) => {
  const remoteBook = book || await getBookFromRemoteDB(bookId);
  await saveBookToLocalDB(remoteBook);
};

export const syncRemoteAndLocalBookChanges = async (
  remoteBook: IBookStore.RemoteBook,
  localBook: IBookStore.ExpandedBook
): Promise<void> => {
  /**
   * there can not be any remotebooks with !lastUpdatedAt
   * there can not be any localbooks available both locally and remotely with !lastSuccessfulSync
   */
  if (!remoteBook.lastUpdateAt || !localBook.lastSuccessfulSync) {
    return;
  }
  /** remotebook has no new updates */
  if (remoteBook.lastUpdateAt === localBook.lastSuccessfulSync) {
    /** localbook has no new updates */
    if (localBook.allChangesSynced) {
      return;
      /** localbook has unsynced new updates, save localbook to remote db */
    } else {
      const bookToSaveInRemoteDB = localBookToRemoteBook(localBook);
      const timestamp = await updateBookInRemoteDB(bookToSaveInRemoteDB);
      await patchBookInLocalDB({
        _id: bookToSaveInRemoteDB._id,
        lastSuccessfulSync: timestamp,
        lastUpdateAt: timestamp,
        allChangesSynced: true,
      });
    }
    /** remotebook has new updates since the locabook's last successful sync */
  } else {
    /** localbook has no new updates, save remotebook to localdb */
    if (localBook.allChangesSynced) {
      await saveBookToLocalDB(remoteBook);
      /** localbook has new updates, should conflict resolve */
    } else {
      /** get conflict resolved book */
      const commonBook = doChapterLevelBookSync(remoteBook, localBook);
      const bookToSaveInRemoteDB = localBookToRemoteBook(commonBook);
      /** update remote db with the conflict resolved book */
      const timeStamp = await updateBookInRemoteDB(bookToSaveInRemoteDB);
      /** update localbook with the conflict resolved book */
      await saveBookToLocalDB({ ...commonBook, lastUpdateAt: timeStamp });
    }
  }
};

/**
 * @param remoteBook Book from the server
 * @param localBook Book from local db
 * @returns A book that has changes from both server and local db
 *
 * If a chapter exists in both remote and local book, the chapter meta from the remote book gets
 * prioritized. However, the chapter bodies should merge accordingly using yjs elsewhere
 * in the sync logic.
 */
export const doChapterLevelBookSync = (
  remoteBook: IBookStore.RemoteBook,
  localBook: IBookStore.ExpandedBook
): IBookStore.ExpandedBook => {
  /**
   * Remove deleted chapters in the remote book, from the localbook.
   * Chapters can not be deleted while offline, therefore the localbook should not have
   * newly deleted chapters.
   */
  if (remoteBook.deletedChapterIds?.length) {
    const isDeletedChapter = (chapter: IChapterStore.ChapterMeta) =>
      remoteBook.deletedChapterIds?.includes(chapter._id);
    localBook.chapters =
      localBook.chapters?.filter(isDeletedChapter);
    localBook.frontMatter =
      localBook.frontMatter?.filter(isDeletedChapter);
  }
  const syncedBook = remoteBook;
  /** 
   * Append chapters / chapterids missing in remote book (chapters created offline) to 
   * the resolving book
   */
  const chaptersMissingInRemoteBook = getObjectArrayDifference(
    localBook.chapters,
    remoteBook.chapters,
    "_id"
  );
  const chapterIdsMissingInRemoteBook = chaptersMissingInRemoteBook.map(
    (chapter) => chapter._id
  );
  const frontMatterMissingInRemoteBook = getObjectArrayDifference(
    localBook.frontMatter,
    remoteBook.chapters,
    "_id"
  );
  const frontMatterIdsMissingInRemoteBook = frontMatterMissingInRemoteBook.map(
    (chapter) => chapter._id
  );

  syncedBook.chapters = [
    ...syncedBook.chapters,
    ...[...chaptersMissingInRemoteBook, ...frontMatterMissingInRemoteBook],
  ];
  syncedBook.chapterIds = [
    ...syncedBook.chapterIds,
    ...chapterIdsMissingInRemoteBook,
  ];
  syncedBook.frontMatterIds = [
    ...syncedBook.frontMatterIds,
    ...frontMatterIdsMissingInRemoteBook,
  ];
  return syncedBook as IBookStore.ExpandedBook;
};


export const syncBookBaseData = async (bookId: string): Promise<IBookStore.ExpandedBook> => {
  await syncBook(bookId);
  await syncBookChapterBodies(bookId);
  return await getBookFromIDB(bookId);
};

export const syncBookData = async (bookId: string): Promise<IBookStore.ExpandedBook> => {
  await syncBook(bookId);
  return await getBookFromIDB(bookId);
};

export const syncAllBooks = async (bookIds: string[], callback?: (book: IBookStore.ExpandedBook) => void) => {
  const timeout = 5000;
  for (let i = 0; i < bookIds.length; i++) {
    try {
      // Wait up to `timeout` ms for each book, but allow the book to resolve later if needed
      const book = await withTimeout(syncBookBaseData(bookIds[i]), timeout);
      if (callback)
        callback(book);
    } catch (error: any) {
      if (error.message === "Timeout exceeded") {
        console.warn(`Book ${bookIds[i]} took too long, moving to the next book`);
        // Handle long-taking books asynchronously so they can still append later
        syncBookBaseData(bookIds[i])
          .then((book) => callback ? callback(book) : null)
          .catch((err) => console.error(`Failed to load book ${bookIds[i]} after timeout:`, err));
      } else {
        console.error(`Failed to load book ${bookIds[i]}:`, error);
      }
    }
  }
};

export const syncBooks = async (callback?: (book: IBookStore.ExpandedBook) => void) => {
  const { toSync, onlyInLocal, onlyInServer } = await invokeSyncApi();
  const allBookIds = [...onlyInLocal, ...onlyInServer, ...toSync];

  if(allBookIds.length > 0){
    await syncAllBooks(allBookIds, callback);
  }
};

const invokeSyncApi = async () => {
  const [books, collaboratedBooks] = await GetBooksFromDB();
  const sanitizedBooks: any = {};

  [...books, ...collaboratedBooks].forEach(book => {
    sanitizedBooks[book._id] = {
      lastUpdateAt: book.lastUpdateAt,
      lastSuccessfulSync: book.lastSuccessfulSync,
      allChangesSynced: book.allChangesSynced
    };
  });

  return await AtticusClient.BookSync(sanitizedBooks);
};
