


import React, { useState } from "react";
import { Button, Col, Popconfirm } from "antd";
import { AddImageIcon } from "../../../../content/icons";
import Can from "../../../casl/Can";
import { DeleteOutlined } from "@ant-design/icons";
import IndividualChapterImageUpload from "../../../Shared/Uploads/IndividualChapterImage";
import { observer } from "mobx-react-lite";
import useRootStore from "../../../../store/useRootStore";

interface ChapterImageProps {
  url: string;
  onImageChange: (url: string) => void;
}

export const ChapterImage = observer(({ url, onImageChange }: ChapterImageProps) => {
  const [showImgDialog, setShowImgDialog] = useState(false);
  const [chapterImageUrl, setChapterImageUrl] = useState(url || "");
  const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;
  const { syncActiveChapterMeta } = useRootStore().chapterStore;

  const handler = (img: string) => {
    setChapterImageUrl(img);
    setShowImgDialog(false);
    setIsChapterLocalUpdate(true);
    setChangeEvent("chapter-contents-change");
    syncActiveChapterMeta({ image: img });
    onImageChange(img);
  };

  return (
    <>
      {url ? (
        <Col>
          <div
            className="title-bar-image-previewer"
            style={{ backgroundImage: `url(${chapterImageUrl})` }}
          >
            <div className="title-bar-image-previewer-container">
              <Popconfirm
                title="Are you sure you want to delete this image?"
                onConfirm={() => handler("")}
                okButtonProps={{
                  danger: true,
                }}
                okText="Delete"
                cancelText="No"
              >
                <Button
                  size="middle"
                  danger
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </div>
          </div>
        </Col>
      ) : (
        <Col>
          <Can action={"view"} subject={"add-chapter-image"} passThrough>
            {(allowed) => (
              <a
                onClick={() =>
                  allowed ? setShowImgDialog(!showImgDialog) : null
                }
              >
                <div className="title-bar-image-container">
                  <p>{allowed}</p>
                  <AddImageIcon />
                </div>
              </a>
            )}
          </Can>
        </Col>
      )}
      <IndividualChapterImageUpload
        show={showImgDialog}
        handler={handler}
      />
    </>
  );
});

