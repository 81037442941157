import { HeaderImage } from "@surge-global-engineering/css-generator";

import {
  parentBlockTypes,
  fsPatentBlockTypes,
  CurrentChapterConfig,
  shouldRenderFPFormattingForChapter,
  isChapterTitleCardRequired,
  fsForwardingBlockTypes,
} from "../helpers";

import {
  parseBaseParentBlock,
  parseImage,
  parseSocialProfile,
  parseList,
  parseEndnotes,
  parseOrnamentalBreak,
  parseChapterHeader
} from ".";
import { parseTextMessages } from "./textMessages";
import { SlateTextMessages } from "../../../types/slate";
import { filterTrackChanges } from "../../../utils/track-changes";
import { parsepageBreak } from "./pageBreak";


export const parseChapter = (
  chapter: IChapterStore.Chapter,
  config: IThemeStore.ThemeStyleProps,
  isEndnote?: boolean,
  number?: number,
  headerPrefix?: string,
  isExport?: boolean,
  headerImage?: HeaderImage,
  parsedEndnotes?: ISlateStore.SlateEndnote[],
  endnotesOffset?: number,
  endnoteChapter?: IChapterStore.Chapter,
  subheadings?: ISlateStore.SlateSubheading[],
): string => {

  let html = !isEndnote ? `<div class="${chapter.type}">` : "";
  const chapterId = isExport? chapter._id : undefined;

  if(!isEndnote){

    /** render chapter header */
  /** chapter level config to show/hide image */
  const { showChapterImage = true } = chapter?.configuration || {};
  /** chapter level config to show/hide header */
  const { showChapterHeading = true } = chapter?.configuration || {};

  const canRenderInvidualChapterImage =
    config.individualChapterImage && chapter.image ;
  const shouldRenderHeaderImage =
   showChapterImage && 
    Boolean(
      config.titleCard?.image &&
        (config.image?.url || canRenderInvidualChapterImage)
    );

  const imageUrlToRender = headerImage?.url? headerImage?.url: config.individualChapterImage ? chapter.image : config.image?.url;
  const headerElements: IChapterStore.ChapterHeaderElements = {
    title: chapter.title,
    subtitle: chapter.subtitle,
    number,
    image: shouldRenderHeaderImage ? imageUrlToRender: undefined
  };

  const { showDefaultTextColour = true } = chapter?.configuration || {};
  const isLightText = config.image.placement === "background-image" && config.image.headerTextColor === "light";
  const isInverted = showDefaultTextColour ? isLightText : !isLightText;

  if (isChapterTitleCardRequired(chapter.type) && showChapterHeading) {
    html += parseChapterHeader({
      styleProps: config,
      headerElements,
      classPrefix: headerPrefix,
      isInverted
    },
    isExport);
  }}

  const { showFirstSentenceFormatting = true } = chapter.configuration || {};

  const chapterAllowsFSF =  showFirstSentenceFormatting &&
  !isEndnote &&
  shouldRenderFPFormattingForChapter(chapter.type);

  const drpCapCls =
    showFirstSentenceFormatting &&
    !isEndnote &&
    shouldRenderFPFormattingForChapter(chapter.type)
      ? "withDropcap"
      : "";
  /** render chapter body */
  !isEndnote?
    html += `<div class="chapter-body ${drpCapCls}">`:"";

  html += !isEndnote ? "<div class=\"wrapper\">": "";

  const chapterConfig = new CurrentChapterConfig();

  // filter out the track-changes from the chapter body
  const filteredChapterContent = filterTrackChanges(chapter.children );
  let parsedFirstParagraph = false;
  for (const [blockIndex, block] of filteredChapterContent.entries()) {
    // Flag the first paragraph at chapter level to control first-sentence formatting
    if(block.type === "p" || block.type === "align_left") {
      parsedFirstParagraph = true;
    }
    if (parentBlockTypes.includes(block.type)) {
      html += parseBaseParentBlock(
        block as ISlateStore.SlateBaseParentBlockNode,
        chapterConfig,
        false,
        isEndnote,
        isExport,
        chapterId,
        endnoteChapter,
        endnotesOffset,
        config,
        subheadings,
        chapterAllowsFSF
      );
    }

    if (fsPatentBlockTypes.includes(block.type)) {
      html += parseBaseParentBlock(
        block as ISlateStore.SlateBaseParentBlockNode,
        chapterConfig,
        chapterConfig.getFSFormatting(),
        isEndnote,
        isExport,
        chapterId,
        endnoteChapter,
        endnotesOffset,
        config,
        subheadings,
        chapterAllowsFSF 
      );
    }

    //apply drop cap in the next paragraph if type is ornamental break
    if (block.type === "ornamental-break" && config.sceneBreakShowSetting !== "no-break") {
      html += parseOrnamentalBreak(config);
      if (config.beginFirstSentence === "break") {
        chapterConfig.setFSFormatting(true);
      }
    }

    // parse image node
    if (block.type === "image") {
      const imageHtml = parseImage(
        block as ISlateStore.SlateImage,
        chapter.children[blockIndex - 2],
        chapter.children[blockIndex - 1],
        chapter.children[blockIndex + 1],
        chapter.children[blockIndex + 2],
        isExport
      );
      html += imageHtml;
    }

    // parse profile links
    if (block.type === "profile") {
      const profileHtml = parseSocialProfile(
        block as ISlateStore.SlateProfileLink
      );
      html += profileHtml;
    }

    
    if (block.type === "text_messages") {
      html += parseTextMessages(block as SlateTextMessages);
    }

    // parse list nodes
    if (block.type === "ul" || block.type === "ol") {
      html += parseList(
        block as ISlateStore.SlateListBlock, 
        chapterConfig, 
        undefined, 
        isExport, 
        endnotesOffset, 
        chapterId, 
        endnoteChapter, 
        config
        );
    }

    // If we encounter a fsForwardingBlockTypes block before first paragraph in a chapter,
    // enable first-sentence formatting for the next paragraph
    if (!parsedFirstParagraph && fsForwardingBlockTypes.includes(block.type)) {
      chapterConfig.setFSFormatting(true);
    }

    // parse page breaks
    if (block.type === "page-break" && isExport) {
      const nextNode = chapter.children[blockIndex + 1];
      const nextNextNode = chapter.children[blockIndex + 2];
      html += parsepageBreak(nextNode, nextNextNode);
    }
  }

  html += !isEndnote ? "</div>" : "";

  // render endnotes
  if (
    chapterConfig.getEndnotes().length > 0 &&
    config.ePubNotesMode !== "END_OF_BOOK"
  ) {
    html += parseEndnotes(chapterConfig.getEndnotes(), undefined, chapter, config, isExport);
  }

  html += !isEndnote ? "</div>" : "";
  html += !isEndnote ? "</div>" : "";
  

  if (parsedEndnotes) parsedEndnotes.push(...chapterConfig.getEndnotes());
  return html;
};
