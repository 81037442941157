import { HeaderFontStyle } from "@surge-global-engineering/css-generator";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../utils/chapter";
import { MyRootBlock } from "../components/Plate/config/typescript";
import { ThemeFieldFontStyle } from "../types/themeForm";

interface CopyrightTemplate {
  title: string;
  children: MyRootBlock[];
}


export const initBook : IBookStore.ExpandedBook = {
  _id: "",
  chapterIds: [],
  chapters: [],
  frontMatterIds: [],
  deletedChapterIds: [],
  frontMatter: [],
  language: "en",
  isbn: "",
  printISBN: "",
  ebookISBN: "",
  publisherName: "",
  publisherLogoURL: "",
  publisherLink: "",
  coverImageUrl: "",
  title: "",
  subtitle: "",
  project: "",
  author: [],
  lastUpdateAt: new Date(),
  themeId: ""
};

export const initTemplate : IChapterStore.IChapterTemplateBase = {
  _id: "",
  bookId: "",
  motherChapterId: "",
  type: "chapter",
  title: "",
  titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
  subtitle: "",
  section: "",
  image: "",
  index: 0,
  numbered: false,
  children: []
};

export const initBody = [
  {
    type: "p",
    children: [
      {
          text: "",
      }
    ],
  },
] as MyRootBlock[];

export const copyrightTemplates = [
  {
    "title": "Simple copyright",
    "children": [
      {
        "type": "p",
        "children": [
          {
            "text": "Copyright © [Year of First Publication] by [Author or Pen Name] "
          }
        ]
      },
      { "type": "p", "children": [{ "text": "All rights reserved." }] },
      {
        "type": "p",
        "children": [
          {
            "text": "No portion of this book may be reproduced in any form without written permission from the publisher or author, except as permitted by U.S. copyright law."
          }
        ]
      }
    ]
  },
  {
    "title": "General fiction copyright",
    "children": [
      {
        "type": "p",
        "children": [
          {
            "text": "Copyright © [Year of First Publication] by [Author or Pen Name]              "
          }
        ]
      },
      { "type": "p", "children": [{ "text": "All rights reserved." }] },
      {
        "type": "p",
        "children": [
          {
            "text": "No part of this publication may be reproduced, distributed, or transmitted in any form or by any means, including photocopying, recording, or other electronic or mechanical methods, without the prior written permission of the publisher, except as permitted by U.S. copyright law. For permission requests, contact [include publisher/author contact info]."
          }
        ],
      },
      {
        "type": "p",
        "children": [
          {
            "text": "The story, all names, characters, and incidents portrayed in this production are fictitious. No identification with actual persons (living or deceased), places, buildings, and products is intended or should be inferred."
          }
        ],
      },
      {
        "type": "p",
        "children": [{ "text": "Book Cover by [Artist]" }],
      },
      {
        "type": "p",
        "children": [{ "text": "Illustrations by [Illustrator]" }],
      },
      {
        "type": "p",
        "children": [
          { "text": "[Edition Number] edition [Year of Publication]" }
        ],
      }
    ]
  },
  {
    "title": "General nonfiction copyright",
    "children": [
      {
        "type": "p",
        "children": [
          {
            "text": "Copyright © [Year of First Publication] by [Author or Pen Name]                        "
          }
        ]
      },
      {
        "type": "p",
        "children": [{ "text": "All rights reserved." }],
      },
      {
        "type": "p",
        "children": [
          {
            "text": "No portion of this book may be reproduced in any form without written permission from the publisher or author, except as permitted by U.S. copyright law."
          }
        ],
      },
      {
        "type": "p",
        "children": [
          {
            "text": "This publication is designed to provide accurate and authoritative information in regard to the subject matter covered. It is sold with the understanding that neither the author nor the publisher is engaged in rendering legal, investment, accounting or other professional services. While the publisher and author have used their best efforts in preparing this book, they make no representations or warranties with respect to the accuracy or completeness of the contents of this book and specifically disclaim any implied warranties of merchantability or fitness for a particular purpose. No warranty may be created or extended by sales representatives or written sales materials. The advice and strategies contained herein may not be suitable for your situation. You should consult with a professional when appropriate. Neither the publisher nor the author shall be liable for any loss of profit or any other commercial damages, including but not limited to special, incidental, consequential, personal, or other damages."
          }
        ],
      },
      {
        "type": "p",
        "children": [{ "text": "Book Cover by [Artist] " }],
      },
      {
        "type": "p",
        "children": [{ "text": "Illustrations by [Illustrator]" }],
      },
      {
        "type": "p",
        "children": [
          { "text": "[Edition Number] edition [Year of Publication]" }
        ],
      }
    ]
  },
  {
    "title": "Public domain copyright",
    "children": [
      {
        "type": "p",
        "children": [
          {
            "text": "[Source Material Title] is in the public domain. All original additions, including illustrations and chapter summaries, are copyright © [Year of First Publication] by [Author or Pen Name] and may not be reproduced in any form without written permission from the publisher or author, except as permitted by U.S. copyright law.       "
          }
        ]
      },
      {
        "type": "p",
        "children": [{ "text": "Book Cover by [Artist]" }],
      },
      {
        "type": "p",
        "children": [{ "text": "Illustrations by [Illustrator]" }],
      },
      {
        "type": "p",
        "children": [
          { "text": "[Edition Number] edition [Year of Publication]" }
        ],
      }
    ]
  }
] as CopyrightTemplate[];

const uncategorised = {
  id: "uncategorised",
  name: "Chapter",
};

export const chapterTypeMap = new Map([
  ["half-title", "Half title"],
  ["title", "Title Page"],
  ["endnotes", "Endnotes"],
  ["copyrights", "Copyrights"],
  ["epigraph", "Epigraph"],
  ["introduction", "Introduction"],
  ["preface", "Preface"],
  ["prologue", "Prologue"],
  ["epilogue", "Epilogue"],
  ["acknowledgments", "Acknowledgments"],
  ["afterword", "Afterword"],
  ["foreword", "Foreword"],
  ["also-by", "Also by"],
  ["about-author", "About author"],
  ["dedication", "Dedication"],
  ["blurbs", "Blurbs"],
  ["image", "Fullpage image"]
]);

export const themeFormDefaults : IThemeStore.ThemeFields = {
  textLight: "default",
  numberview: "chapter+number",
  beginFirstSentence: "chapter",
  decorations: [],
  ob: "",
  ob_width: 50,
  sceneBreakShowSetting: "no-break",
  firstline: "",
  paraAlignment: [],
  layout: "layout6",
  coloredImg: "all",
  fontsize: 10,
  printBaseFont: "LibreCaslon",
  linespacing: 1.5,
  trim_unit: "in",
  trimsize: "",
  isLargeTrim: false,
  margin_unit: "in",
  margin_in: 1.27,
  margin_out: 2.2225,
  margin_top: 0.8,
  margin_bottom: 0.8,
  hanging_indent: 0.381,
  notesMode: "FOOTNOTE",
  ePubNotesMode: "END_OF_CHAPTER",
  footnoteFontSize:1,
  pdfEndnotesChapterSettings:[],
  ePubEndnotesChapterSettings: [],
  dynamicPageBreaks: [],
  name: "",
  image: "",
  individualChapterImage: false,
  bgPrintExtent: "margins",
  imgVisible: false,
  imgWidth: 50,
  imgAlignment: "center",
  imgOpacity: 100,
  imgPlacement: "above-chapter-no",
  chpNumVisible: false,
  chpNumFont: "",
  chpNumStyle: ThemeFieldFontStyle.regular,
  chpNumSize: 0,
  chpNumAlign: "center",
  chpNumWidth: 0,
  chpTitleVisible: false,
  chpTitleFont: "",
  chpTitleStyle: ThemeFieldFontStyle.regular,
  chpTitleSize: 0,
  chpTitleAlign: "center",
  chpTitleWidth: 0,
  chpSubtitleVisible: false,
  chpSubtitleFont: "",
  chpSubtitleStyle: ThemeFieldFontStyle.regular,
  chpSubtitleSize: 0,
  chpSubtitleAlign: "center",
  chpSubtitleWidth: 0,
  headerFont: "",
  headerSize: 0,
  footerFont: "",
  footerSize: 0,
  layoutPriority: "WIDOWS_AND_ORPHANS",
  h2Size: 1.20,
  h3Size: 1.15,
  h4Size: 1.12,
  h5Size: 1.09,
  h6Size: 1.07,
  h2Font: "Default",
  h3Font: "Default",
  h4Font: "Default",
  h5Font: "Default",
  h6Font: "Default"
};

export const DEFAULT_THEME_ID = "default";
export const DEFAULT_THEME_NAME = "default";


export const themedefaults: IThemeStore.Theme = {
  _id: DEFAULT_THEME_ID,
  name: "",
  css: "",
  isPredefinedTheme: false,
  fonts: [],
  isFavourite: false,
  properties: {
    individualChapterImage: false,
    baseFontSize: 10,
    printBaseFont: "EBGaramond",
    layout: "layout1",
    layoutPriority: "WIDOWS_AND_ORPHANS",
    beginFirstSentence: "chapter",
    chapterNumbering: "chapter+number",
    ornamentalBreakImage: "",
    sceneBreakShowSetting: "break-with-image",
    ornamentalBreakWidth: 100,
    notesMode: "FOOTNOTE",
    ePubNotesMode: "END_OF_CHAPTER",
    footnoteFontSize:1, 
    titleCardExtras: {
      digital : {
        paddingTop: 12,
        paddingBottom: 12
      },
      print: {}
    },
    image: {
      url: "",
      alignment: "center",
      printExtent: "margins",
      opacity: 100,
      width: 50,
      colored: "all",
      placement: "above-chapter-no",
      headerTextColor: "default"
    },
    chapterNo: {
      font: "Aldrich",
      size: 28,
      fontSizeOffset: 0,
      style: [HeaderFontStyle.regular],
      align: "left",
      width: 100,
      extras: {}
    },
    chapterTitle: {
      font: "Aldrich",
      style: [HeaderFontStyle.regular],
      size: 33,
      fontSizeOffset: 0,
      align: "left",
      width: 100,
      extras: {}
    },
    chapterSubtitle: {
      font: "Aldrich",
      style: [HeaderFontStyle.regular],
      size: 24,
      fontSizeOffset: 0,
      align: "left",
      width: 100,
      extras: {}
    },
    header: {
      font: "EBGaramond",
      size: 3
    },
    footer: {
      font: "EBGaramond",
      size: 3
    },
    dynamicPageBreaks: {
      breakSubheadings: false,
      breakOrnamentalBreaks: false,
    },
    paragraph: {
      paragraphSpacing: 1.6,
      indent: 1,
      hyphens: true,
      justify: true
    },
    trim: {
      height: 20.32,
      width: 12.7,
      isLargeTrim: false
    },
    firstParagraph: {
      uppercaseFourWords: false,
      indent: 0,
      dropcap: true,
    },
    margin: {
      unit: "in",
      bottom: 0.8,
      top: 0.8,
      outer: 1.27,
      inner: 2.2225
    },
    hangingIndent: 0.381,
    titleCard: {
      chapterNumber: true,
      title: true,
      subtitle: false,
      image: false,
    },
    pdfEndnotesChapterSettings: {
      showTitle: false,
      categorizeByTitle: false 
    },
    ePubEndnotesChapterSettings: {
      showTitle: false,
      categorizeByTitle: false 
    },
    headings: {
      h2:{
        size: 1.20,
        font: "Default"
      },
      h3: {
        size: 1.15,
        font: "Default"
      },
      h4: {
        size: 1.12,
        font: "Default"
      },
      h5: {
        size: 1.09,
        font: "Default"
      },
      h6: {
        size: 1.07,
        font: "Default"
      }
    }
  }
};

export const themeMetaDefaults: Omit<IThemeStore.ThemeMeta, "_id" | "name"> = {
  css: "",
  isFavourite: false,
  isPredefinedTheme: false,
  fonts: []
};


export const getPresentableChapterType = (
  id: IChapterStore.ChapterType
): string => chapterTypeMap.get(id) || "Chapter";
