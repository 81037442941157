


import React, { useState } from "react";
import { Input } from "antd";
import { can } from "../../../casl/helpers";
import { PlateEditor } from "@udecode/plate";
import useRootStore from "../../../../store/useRootStore";

interface TitlebarProps {
  initialValue: string,
  onEnter: any;
  autofocus: boolean;
  onTitleChange: (title: string) => void;
}

export const ChapterTitle = ({ initialValue, autofocus, onEnter, onTitleChange }: TitlebarProps) => {
  const [title, setTitle] = useState(initialValue || "");
  const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;
  
  const handler = (val: string) => {
    setTitle(val);
    setIsChapterLocalUpdate(true);
    setChangeEvent("chapter-title-change");
    onTitleChange(val);
  };

  return (
    <div>
      <Input
        className="att-editor-title"
        type="text"
        value={title}
        disabled={!can("view", "chapter-title-edit")}
        placeholder={"Chapter Title"}
        autoFocus={autofocus}
        bordered={false}
        onChange={(event) => handler(event.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onEnter();
          }
        }}
      />
    </div>
  );
};

